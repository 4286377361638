import React from "react";

/* eslint-disable-next-line no-restricted-imports */
import { CheckCircle, Close, Error, Info, Warning } from "@mui/icons-material";
import { IconButton, Snackbar, SnackbarContent } from "@mui/material";
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { flashClose } from "Actions/flash";

import styles from "./Flash.module.scss";

const variantIcon = {
  success: CheckCircle,
  warning: Warning,
  error: Error,
  info: Info,
};

const StyledSnackbarContent = React.forwardRef((props, ref) => {
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];
  return (
    <SnackbarContent
      className={classnames(className, {
        [styles.error]: variant === "error",
        [styles.success]: variant === "success",
        [styles.warning]: variant === "warning",
        [styles.info]: variant === "info",
      })}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={styles.message}>
          <Icon className={classnames(styles.icon, styles.messageIcon)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={styles.close}
          onClick={onClose}
          size="large"
        >
          <Close className={styles.icon} />
        </IconButton>,
      ]}
      ref={ref}
      {...other}
    />
  );
});

class Flash extends React.Component {
  static propTypes = {
    message: PropTypes.string.isRequired,
    level: PropTypes.oneOf(["error", "success", "warning", "info"]).isRequired,
    flashClose: PropTypes.func.isRequired,
    userAuthenticated: PropTypes.bool,
    autoHideDuration: PropTypes.number,
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.props.flashClose();
  };

  render() {
    const { message, level, userAuthenticated, autoHideDuration } = this.props;
    if (message && message.length > 0) {
      return (
        <Snackbar
          open
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={autoHideDuration}
          onClose={this.handleClose}
          classes={{
            anchorOriginTopRight: classnames(styles.topRightPosition, {
              [styles.positionAuthenticated]: userAuthenticated,
              [styles.positionPublic]: !userAuthenticated,
            }),
          }}
        >
          <StyledSnackbarContent variant={level} message={message} onClose={this.handleClose} />
        </Snackbar>
      );
    } else {
      return null;
    }
  }
}

function mapStateToProps(state) {
  return state.flash;
}

export default connect(mapStateToProps, { flashClose })(Flash);
