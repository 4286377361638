import { useEffect, useState } from "react";

import { setRef } from "@mui/material";

import { InvoiceCheckoutWithElements } from "Routes/invoices/[uuid]/(steps)/CheckoutStep";
import { useGetAccount, useOrgPayingCustomer } from "Shared/api";
import { Invoice, SKUS, SubscriptionAPI } from "Shared/api/v2";
import { usePurchasables } from "Shared/api/v2/hooks/purchasables";
import { useOrgSubscriptions } from "Shared/api/v2/hooks/subscriptions";
import { Purchasable } from "Shared/api/v2/purchasables";
import { Subscription } from "Specs/v2/components";

import { SignatureUpgradeCheckout } from "./SignatureUpgradeCheckout";

const proratedRefundLineItemsForSubscription = (sub: Subscription, purchasables: Purchasable[]) => {
  if (!sub || !(sub.status === "active")) return [];

  const currentDate = new Date();
  const createdDate = new Date(sub.createdAt);
  const periodEnd = new Date(sub.currentPeriodEnd);

  const secondsRemaining = (periodEnd.getTime() - currentDate.getTime()) / 1000;

  const percentRefund = secondsRemaining / (-365 * 3600 * 24);

  if (
    sub.items.find(item => item.sku === SKUS.IRA_ACCOUNT) &&
    createdDate > new Date("2024-08-01") &&
    createdDate.getTime() > currentDate.getTime() - 365 * 24 * 3600 * 1000
    // Checking if IRA subscription is less than 1 year old, meaning that
    // 1st year free discount is applied and thus there will be no refund
  ) {
    return [];
  }

  return sub.items.map(item => {
    const p = purchasables.find(p => p.sku === item.sku);
    const text = p ? `Unused time on ${p.name}` : `Unused time on account`;

    return {
      text,
      quantity: 1,
      sku: item.sku,
      usdPrice: (parseFloat(item.usdAmount) * percentRefund).toFixed(2),
    };
  });
};

const proratedRefundLineItems = (subs: Subscription[], purchasables: Purchasable[]) =>
  subs.flatMap(s => proratedRefundLineItemsForSubscription(s, purchasables)).filter(Boolean);

const nonPersonalOrgSubscriptions = async (uuids: string[]) => {
  const subscriptions = await Promise.all(
    uuids.map(uuid => SubscriptionAPI.List({ orgId: uuid }).then(res => res.data))
  );
  return subscriptions.flat();
};

export default function SignatureCheckout() {
  const { data: accountData, error, isLoading } = useGetAccount();
  const payingCustQuery = useOrgPayingCustomer(accountData?.personalOrg.uuid);
  const purchasablesQuery = usePurchasables();
  const orgPayingCustomer = payingCustQuery.data;
  const personalSubQuery = useOrgSubscriptions(accountData?.personalOrg.uuid);
  const [refundLineItems, setRefundLineItems] = useState([]);
  const [selectedSignatureSku, setSelectedSignatureSku] = useState(SKUS.SIGNATURE);

  useEffect(() => {
    const relevantNonPersonalOrgs = accountData?.memberships
      .filter(
        membership =>
          ["ira", "trust"].includes(membership.org.account_type) &&
          membership.org.owner.uuid === accountData.user.uuid
      )
      .map(membership => membership.org.uuid);
    const activePersonalSubs = personalSubQuery.data?.data?.filter(sub => sub.status === "active");

    let activePersonalSub;
    if (activePersonalSubs?.length > 1) {
      throw new Error("Too many active subscriptions");
    } else if (activePersonalSubs?.length === 1) {
      activePersonalSub = activePersonalSubs[0];
    }

    if (!purchasablesQuery.data) return;

    nonPersonalOrgSubscriptions(relevantNonPersonalOrgs).then(nonPersonalSubs => {
      setRefundLineItems(
        proratedRefundLineItems(
          [activePersonalSub, ...nonPersonalSubs],
          purchasablesQuery.data.purchasables
        )
      );
    });
  }, [accountData?.memberships, personalSubQuery.data, purchasablesQuery.data]);

  const invoice = {
    status: "NOT_STARTED",
    lineItems: [
      {
        quantity: 1,
        sku: selectedSignatureSku,
      },
      ...refundLineItems,
    ],
    discounts: [],
    creditItems: [],
    tax: "0",
    orgId: accountData.personalOrg.uuid,
    subscriptionId: "",
  };

  return (
    <SignatureUpgradeCheckout
      invoice={invoice as Invoice}
      setSelectedSignatureSku={setSelectedSignatureSku}
    />
  );
}
