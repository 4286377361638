import { useContext, useEffect, useState } from "react";

import { Dropdown, DropdownItem } from "@unchained/component-library";

import { getUsdAmountFromBtc } from "Components/VaultsView/Show/WithdrawModal/functions";
import { useCurrentOrgDeprecated, useMemoizedState } from "Redux/selectors/hooks";
import { formatNumber } from "Utils/strings";

import { InitialBannerWarning } from "./InitialBannerWarning";
import { WithdrawModalContext } from "./context";
import { IWithdrawModalContext } from "./types";

const getOptions = (destinations: IWithdrawModalContext["transferDestination"][]) =>
  destinations.map(destination => {
    let label: string;

    switch (destination.product_type) {
      case "vault":
        label = `Vault ${destination.uuid}`;
        break;

      case "loan":
        label = `Loan ${destination.uuid}`;
        break;

      default:
        label = destination.uuid;
        break;
    }

    return { value: destination, label };
  });

const OptionItem = ({ label, value: product, ...rest }) => {
  // This component is a mess, but it's required due to the component lib
  // Dropdown not being very flexible. It expects strings for a label and
  // description to place inside of a span, but here I force it to accept a
  // structure of spans to get the proper styling that is required.
  const {
    BTC: { value: usdPrice },
  } = useMemoizedState("crypto.prices.current");

  const defaultDescriptionText = `${formatNumber(product.balance, 4, true, true)} BTC`;
  let descriptionEl = <span>{defaultDescriptionText}</span>;
  if (product.product_type === "vault") {
    descriptionEl = (
      <span>
        <span className=" pr-2">{product.name}</span>
        {`${defaultDescriptionText} / $${formatNumber(
          getUsdAmountFromBtc(product.balance, usdPrice),
          2,
          false,
          false
        )}`}
      </span>
    );
  }
  if (product.product_type === "loan") {
    descriptionEl = <span>{`Principal: $${formatNumber(product.principal, 2, false, true)}`}</span>;
  }

  const Description = () => descriptionEl;

  return (
    <DropdownItem
      label={
        (<span className="text-sm font-bold text-gray-600">{label}</span>) as unknown as string
      }
      description={
        (
          <span className="text-xs text-gray-600">
            <Description />
          </span>
        ) as unknown as string
      }
      {...rest}
    />
  );
};

export const TransferDestination = () => {
  const org = useCurrentOrgDeprecated();
  const isIra = org.account_type === "ira";

  const { setState, onDismiss } = useContext(WithdrawModalContext);
  const [inputValue, setInputValue] = useState(null);
  const destinations: IWithdrawModalContext["transferDestination"][] =
    useMemoizedState("vaults.vaultShow.vault.transferrable_products") || [];

  useEffect(() => {
    if (!inputValue) {
      setState({ nextDisabled: true });
      return;
    }

    // Everything is okay.
    setState({ transferDestination: inputValue, nextDisabled: false });
  }, [inputValue, setState]);

  return (
    <>
      <Dropdown
        // This is required because the dropdown selector for transfers fights
        // with modals.
        className=" [&>*:nth-child(2)]:h-52"
        fullWidth
        multi={false}
        placeholder="Select"
        Item={OptionItem}
        options={getOptions(destinations)}
        onSelect={({ value: product }) => setInputValue(product)}
      />
      <br />
      {isIra && <InitialBannerWarning onStepperOpen={onDismiss} />}
    </>
  );
};
