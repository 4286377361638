/* eslint-disable-next-line no-restricted-imports */
import { ArrowBack } from "@mui/icons-material";
import { Button } from "@unchained/component-library";

import { StepDescription, StepTop } from "Components/Shared/Layouts/FullPageWizard";

interface CheckoutHeaderProps {
  title: string;
  subtitle: string;
  onBackButton?: () => void;
}
export const CheckoutHeader = ({ title, subtitle, onBackButton }: CheckoutHeaderProps) => {
  return (
    <div className="mt-4 overflow-y-auto">
      {onBackButton ? (
        <Button
          onClick={onBackButton}
          className="mb-2"
          startIcon={<ArrowBack key="back" />}
          type="text"
        >
          Back
        </Button>
      ) : null}
      <StepTop>
        <div>
          <p className="pb-1 font-semi uppercase text-yellow-500">checkout</p>
          <h1 className="pb-1 font-med">{title}</h1>
          <StepDescription>{subtitle}</StepDescription>
        </div>
      </StepTop>
    </div>
  );
};
