import React, { useState } from "react";

import { Fade } from "@mui/material";
import { Button, Icons, useBreakpoint } from "@unchained/component-library";

import { FullPageWizard } from "Components/Shared/Layouts/FullPageWizard";
import { CloseButton } from "Components/Shared/Layouts/FullPageWizard/components";
import { AppModalManager } from "Shared/components/Modals/AppModalManager";

import { FundIraTypes } from "../types";
import { CompleteFormStep } from "./CompleteFormStep";
import { SelectTypeStep } from "./SelectTypeStep";

const fadeOutAnimationTimeout = 250;

type FundIRAStepperWizardBaseProps = {
  closeStepper: () => void;
  initialStep: number;
};
const FundIRAStepperWizardBase = React.memo(
  ({ closeStepper, initialStep }: FundIRAStepperWizardBaseProps) => {
    const [iraFundingType, setIraFundingType] = useState<FundIraTypes>(undefined);
    const wizardSteps = React.useMemo(() => {
      const baseSteps = [
        {
          title: "Select type",
          Component: () => (
            <SelectTypeStep
              selectedIraFundingType={iraFundingType}
              setSelectedIraFundingType={(iraFundingType: FundIraTypes) =>
                setIraFundingType(iraFundingType)
              }
            />
          ),
          complete: iraFundingType,
        },
        {
          title: "Complete form",
          Component: () => <CompleteFormStep selectedIraFundingType={iraFundingType} />,
          complete: false,
          disabled: !iraFundingType,
        },
      ];

      return baseSteps;
    }, [iraFundingType]);

    const handleCloseButtonClick = React.useCallback(() => {
      closeStepper();
    }, []);

    const StepperFooter = () => <Icons.X onClick={closeStepper} />;

    const manifest = React.useMemo(() => {
      return [
        {
          title: "Fund IRA",
          AlternateIcon: () => <CloseButton onClose={handleCloseButtonClick} />,
          steps: wizardSteps,
        },
      ];
    }, [wizardSteps, handleCloseButtonClick]);

    const CloseFooter = () => {
      return (
        <Button
          fullWidth={true}
          type="destructive"
          className="w-full !rounded-none"
          onClick={AppModalManager.close}
        >
          Close
        </Button>
      );
    };
    const { widthIsBelow } = useBreakpoint();
    const isMobile = widthIsBelow("sm");
    const Stepper = React.memo(() => (
      <>
        <FullPageWizard
          startingIndices={"firstIncomplete"}
          isShowUnchainedLogo={isMobile}
          stepperFooter={isMobile ? <StepperFooter /> : <CloseFooter />}
          footerStyles={isMobile ? undefined : "w-full"}
          manifest={manifest}
          headerBackgroundColor={"bg-primary-100"}
        />
      </>
    ));
    return <Stepper />;
  }
);

export const FundIRAStepperWizard = React.memo(({ initialStep = 0 }: { initialStep?: number }) => {
  const [isShowing, setIsShowing] = useState(true);

  const closeStepper = React.useCallback(() => {
    setIsShowing(false);
    setTimeout(() => {
      AppModalManager.close();
    }, fadeOutAnimationTimeout);
  }, []);

  return (
    <Fade in={isShowing} timeout={fadeOutAnimationTimeout}>
      <div>
        <FundIRAStepperWizardBase closeStepper={closeStepper} initialStep={initialStep} />;
      </div>
    </Fade>
  );
});
