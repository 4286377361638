import { API_V2 } from "../api";
import { ClientReportsApiFactory } from "./clientReports";
import { InvoiceAPIFactory } from "./invoices";
import { IRAPlansAPIV2Factory } from "./iraPlans";
import { OrgAPIV2Factory } from "./orgs";
import { PurchasablesAPIFactory } from "./purchasables";
import { SubscriptionAPIFactory } from "./subscriptions";
import { TradingAPIV2Factory } from "./trading";
import { VaultAPIFactory } from "./vaults";

export { ConnectionsAPI } from "./connections";
export { INVOICE_STATUS, SKUS, type Invoice, type PatchInvoice } from "./invoices";
export { RekeysAPI } from "./rekeys";
export { BankAccountApiV2 } from "./bankAccounts";

export const InvoiceAPI = InvoiceAPIFactory(API_V2);
export const SubscriptionAPI = SubscriptionAPIFactory(API_V2);
export const PurchasablesAPI = PurchasablesAPIFactory(API_V2);
export const OrgAPI = OrgAPIV2Factory(API_V2);
export const IRAPlansAPI = IRAPlansAPIV2Factory(API_V2);
export const TradingAPI = TradingAPIV2Factory(API_V2);
export const ClientReportsAPI = ClientReportsApiFactory(API_V2);
export const VaultsAPI = VaultAPIFactory(API_V2);
