export enum FundIraTypes {
  ANNUAL_CONTRIBUTION = "annual_contribution",
  IRA_TO_IRA_TRANSFER = "ira_to_ira_transfer",
  ROLLOVER = "rollover",
}

export enum WithdrawalIraTypes {
  DISTRIBUTION = "distribution",
  TRANSFER_OUT = "transferOut",
}
