/* eslint-disable-next-line no-restricted-imports */
import { ArrowDownward } from "@mui/icons-material";

import { pluralize } from "Utils/strings";

export const PendingDepositNotification = ({
  uuid,
  product_type,
  name,
}: {
  uuid: string;
  product_type: string;
  name: string;
}) => {
  return {
    to: `/${pluralize(product_type)}/${uuid}`,
    title: `Pending deposit for ${name}`,
    Icon: ArrowDownward,
  };
};
