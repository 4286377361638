/* eslint-disable-next-line no-restricted-imports */
import { Group as OrgsIcon } from "@mui/icons-material";

export const PendingOrgMembershipNotification = ({
  name,
  personal,
}: {
  uuid: string;
  name: string;
  personal: boolean;
}) => {
  const orgLabel = personal ? `${name}'s account` : `the account ${name}`;
  return {
    to: "/settings?tab=my-accounts",
    title: `You are invited to join ${orgLabel}`,
    Icon: OrgsIcon,
  };
};
