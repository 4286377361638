/* eslint-disable-next-line no-restricted-imports */
import { Info as InfoIcon } from "@mui/icons-material";

const ProvideProfileInfoNotification = () => {
  return {
    to: "/profile",
    title: `Provide profile information`,
    Icon: InfoIcon,
  };
};

export { ProvideProfileInfoNotification };
