import { Link } from "Components/Link";

export const TradingServiceDisclosure = () => {
  return (
    <div className="mt-2.5 flex flex-row items-center">
      <p className="mr-1">Trading services by Unchained Trading, LLC.</p>

      <Link target="_blank" to={"https://unchained.com/legal-regulatory"}>
        See disclosures.
      </Link>
    </div>
  );
};
