import { ReactNode } from "react";

/* eslint-disable-next-line no-restricted-imports */
import { ArrowForwardRounded } from "@mui/icons-material";

interface Box {
  title: ReactNode;
  caption: ReactNode;
}
export interface TwoBoxChangeProps {
  current: Box;
  target: Box;
}

export function TwoBoxChange({ current, target }: TwoBoxChangeProps) {
  return (
    <div className="mb-4 flex flex-wrap items-center gap-4">
      <div className="w-80 rounded border border-gray-200 bg-gray-50 px-3 py-2">
        <div className="text-md font-semi text-gray-700">{current.title}</div>
        <div className="text-gray-500">{current.caption}</div>
      </div>
      <ArrowForwardRounded />
      <div className="w-80 rounded border border-gray-200 bg-gray-50 px-3 py-2">
        <div className="text-md font-semi text-gray-700">{target.title}</div>
        <div className="text-gray-500">{target.caption}</div>
      </div>
    </div>
  );
}
