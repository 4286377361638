import { Button } from "@unchained/component-library";

import { useSellBitcoinStore } from "Contexts/SellBitcoin/sellBitcoinContext";

import { SellHeader } from "../components/SellHeader";

type SellLimitReachedStepProps = {
  onClose: () => void;
};
export const SellLimitReachedStep = ({ onClose }: SellLimitReachedStepProps) => {
  const { offlineStatus } = useSellBitcoinStore();
  return (
    <div className="w-10/12 max-w-md">
      <SellHeader title={offlineStatus.title} subTitle={offlineStatus.description} />
      <Button
        to={"https://unchainedcapital.formstack.com/forms/sell_order_request"}
        fullWidth={true}
        type={"primary"}
      >
        Request increase to sell limit
      </Button>
      <Button className="mt-3" onClick={onClose} fullWidth={true} type={"text"}>
        Close
      </Button>
    </div>
  );
};
