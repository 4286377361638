import { ReactNode, useState } from "react";

/* eslint-disable-next-line no-restricted-imports */
import { HelpOutline, Warning } from "@mui/icons-material";
import { Grid, IconButton, Table, TableBody, TableRow } from "@mui/material";
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Tooltip,
} from "@unchained/component-library";
import Big from "big.js";
import Papa from "papaparse";

import { ConfirmOnDeviceIcon } from "Components/Shared/Elements/Account/ConfirmAddress/ConfirmOnDeviceIcon";
import { BatchSpendReviewTable } from "Components/Shared/Elements/BatchSpendReviewTable";
import { ExploreAddress } from "Components/Shared/Elements/Crypto/ExploreAddress";
import { AccountNameCell } from "Components/Shared/Elements/Summary/AccountNameCell";
import { CryptoAmountsCell } from "Components/Shared/Elements/Summary/CryptoAmountsCell";
import { OwnerNameCell } from "Components/Shared/Elements/Summary/OwnerNameCell";
import { SummaryTableCell } from "Components/Shared/Elements/Summary/SummaryTableCell";
import { useMemoizedState } from "Redux/selectors/hooks";
import { Loan, Vault } from "Specs/v1/getAccount/200";
import { cryptoToFiat } from "Utils/currency";
import { formatCurrency } from "Utils/strings";

import { getOutputBucketsFromTxOutputs } from "./getOutputBucketsFromTxOutputs";

interface IBatchOutput {
  address: string;
  amount: number;
}

export const getAccountDetails = (operation: { vault?: Vault; loan?: Loan }) => {
  const owner = operation.vault?.owner;
  if (owner) {
    return { isPersonal: !!owner.personal, product: owner };
  }

  const borrower = operation.loan?.borrower;
  if (borrower) {
    return { isPersonal: !!borrower.personal, product: borrower };
  }

  return { isPersonal: true, product: null };
};

const genBatchOutputCsv = (batchOutputs: IBatchOutput[]) => {
  return btoa(
    Papa.unparse(batchOutputs.map(output => ({ Address: output.address, Amount: output.amount })))
  );
};

const ExternalAddressTooltip = ({ multiple = false }: { multiple?: boolean }) => {
  const dest = multiple ? "external addresses" : "an external address";
  const addressName = multiple ? "these addresses and their source" : "this address and its source";
  return (
    <Tooltip
      content={
        <div className="prose">
          <p>
            <strong>You are sending bitcoin to {dest}.</strong> Make sure you have verified{" "}
            {addressName}.
          </p>
          <p>
            <strong>Note: Unchained will never provide you with an external address.</strong>
          </p>
        </div>
      }
    >
      <IconButton className="-ml-2 mb-px">
        <HelpOutline className="h-4 w-4 cursor-pointer text-gray-600" />
      </IconButton>
    </Tooltip>
  );
};

const WarningSummaryTableCell = ({
  multiple = false,
  children,
}: {
  multiple?: boolean;
  children: ReactNode;
}) => {
  return (
    <SummaryTableCell className="flex items-center gap-2 !border-y-0 !border-l-0 bg-yellow-50 [&_a]:!mb-0 [&_a]:!ml-2">
      <Warning className="h-4 w-4 text-yellow-500" />
      {children}
      <ExternalAddressTooltip multiple={multiple} />
    </SummaryTableCell>
  );
};

const ExternalOutputDetails = ({ output, prices }) => {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <SummaryTableCell component="th">To</SummaryTableCell>
          <SummaryTableCell>
            <div>External address</div>
          </SummaryTableCell>
        </TableRow>
        <TableRow>
          <SummaryTableCell component="th">Address</SummaryTableCell>
          <WarningSummaryTableCell>
            <ExploreAddress
              classes={{ text: "text-yellow-500" }}
              address={output.address}
              unit="BTC"
            />
          </WarningSummaryTableCell>
        </TableRow>
        <TableRow>
          <SummaryTableCell component="th">Amount</SummaryTableCell>
          <CryptoAmountsCell
            crypto={`${formatCurrency(output.amount, 8, false, false)} BTC`}
            fiat={`$${formatCurrency(cryptoToFiat(output.amount, "BTC", prices), 2)}`}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

const ProductOutputDetails = ({ output, prices }) => {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <SummaryTableCell component="th">To</SummaryTableCell>
          <AccountNameCell
            name={output.product.name}
            uuid={output.product.uuid}
            product_type={output.product.product_type}
          />
        </TableRow>
        <TableRow>
          <SummaryTableCell component="th">Address</SummaryTableCell>
          <SummaryTableCell>
            <ExploreAddress address={output.address} unit="BTC" />
            {output.product.allowed_actions.includes("create_transaction") && (
              <ConfirmOnDeviceIcon address={output.address} />
            )}
          </SummaryTableCell>
        </TableRow>
        <TableRow>
          <SummaryTableCell component="th">Amount</SummaryTableCell>
          <CryptoAmountsCell
            crypto={`${formatCurrency(output.amount, 8, false, false)} BTC`}
            fiat={`$${formatCurrency(cryptoToFiat(output.amount, "BTC", prices), 2)}`}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

const ChangeOutputDetails = ({
  accountName,
  accountType,
  accountUUID,
  output,
  prices,
  accountDetails,
}) => {
  const orgType = useMemoizedState("account.orgs.current.type");
  const isUnchained = orgType === "unchained";
  const isPersonal = accountDetails?.isPersonal;
  const product = accountDetails?.product;

  return (
    <Table>
      <TableBody>
        <TableRow>
          <SummaryTableCell component="th">From</SummaryTableCell>
          <AccountNameCell name={accountName} uuid={accountUUID} product_type={accountType} />
        </TableRow>
        <TableRow>
          <SummaryTableCell component="th">Change address</SummaryTableCell>
          <SummaryTableCell>
            <ExploreAddress address={output.address} unit="BTC" />
            {output.product.allowed_actions.includes("create_transaction") && (
              <ConfirmOnDeviceIcon address={output.address} />
            )}
          </SummaryTableCell>
        </TableRow>
        {isUnchained && product && (
          <TableRow>
            <SummaryTableCell component="th">Account</SummaryTableCell>
            <OwnerNameCell product={product} isPersonal={isPersonal} />
          </TableRow>
        )}
        <TableRow>
          <SummaryTableCell component="th">Change</SummaryTableCell>
          <CryptoAmountsCell
            crypto={`${formatCurrency(output.amount, 8)} BTC`}
            fiat={`$${formatCurrency(cryptoToFiat(output.amount, "BTC", prices), 2)}`}
          />
        </TableRow>
      </TableBody>
    </Table>
  );
};

const BatchSpendReviewModal = ({
  open,
  onDismiss,
  batchOutputs,
}: {
  open: boolean;
  onDismiss: () => void;
  batchOutputs: IBatchOutput[];
}) => {
  const data = batchOutputs.map(output => ({
    address: { value: output.address },
    amount: { value: String(output.amount) },
  }));

  return (
    <Modal open={open} onDismiss={onDismiss} maxWidth="md" className="md:w-[45.8rem]">
      <ModalHeader>
        <ModalTitle subtitle="Confirm the addresses and amounts before proceeding.">
          Recipient details
        </ModalTitle>
      </ModalHeader>
      <ModalContent>
        <BatchSpendReviewTable data={data} withSummary />
      </ModalContent>
      <ModalFooter
        className="!mt-0"
        actions={[
          { children: "Close", type: "tertiary", onClick: () => onDismiss() },
          {
            children: [
              <a
                download="batch.csv"
                href={`data:application/octet-stream;charset=utf-8;base64,${genBatchOutputCsv(
                  batchOutputs
                )}`}
              >
                Download .csv file
              </a>,
            ],
            type: "text",
          },
        ]}
      />
    </Modal>
  );
};

const BatchOutputDetails = ({
  batchOutputs,
  prices,
}: {
  batchOutputs: IBatchOutput[];
  prices: any;
}) => {
  const [showReviewModal, setShowReviewModal] = useState(false);
  const amount: number = batchOutputs.reduce(
    (acc: number, output: { amount: number }) => Big(acc).plus(output.amount).toNumber(),
    0
  );

  return (
    <>
      <Table>
        <TableBody>
          <TableRow>
            <SummaryTableCell component="th">To</SummaryTableCell>
            <SummaryTableCell>
              <div>External addresses</div>
            </SummaryTableCell>
          </TableRow>
          <TableRow>
            <SummaryTableCell component="th">Addresses</SummaryTableCell>
            <SummaryTableCell>
              <div className="flex flex-row items-center gap-2">
                <span>{`${batchOutputs.length} addresses`}</span>
                <Button type="text" onClick={() => setShowReviewModal(true)}>
                  (View)
                </Button>
              </div>
            </SummaryTableCell>
          </TableRow>
          <TableRow>
            <SummaryTableCell component="th">Amount</SummaryTableCell>
            <CryptoAmountsCell
              crypto={`${formatCurrency(amount, 8, false, false)} BTC`}
              fiat={`$${formatCurrency(cryptoToFiat(amount, "BTC", prices), 2)}`}
            />
          </TableRow>
        </TableBody>
      </Table>
      <BatchSpendReviewModal
        open={showReviewModal}
        onDismiss={() => setShowReviewModal(false)}
        batchOutputs={batchOutputs}
      />
    </>
  );
};

type OutputDetailProps = {
  accountType: string;
  accountUUID: string;
  accountName: string;
  transactionOutputs: any;
  operation: { vault?: Vault; loan?: Loan };
};
export const OutputDetails = ({
  accountType,
  accountUUID,
  accountName,
  transactionOutputs,
  operation,
}: OutputDetailProps) => {
  const prices = useMemoizedState("crypto.prices.current");

  const accountDetails = getAccountDetails(operation);

  const { changeOutputs, productOutputs, externalOutputs } = getOutputBucketsFromTxOutputs(
    transactionOutputs,
    accountType,
    accountUUID
  );

  return (
    <>
      {productOutputs.length > 0 &&
        productOutputs.map((output, idx) => (
          <Grid item xs={12} key={idx}>
            <ProductOutputDetails output={output} prices={prices} />
          </Grid>
        ))}
      {externalOutputs.length > 1 && (
        <Grid item xs={12}>
          <BatchOutputDetails batchOutputs={externalOutputs} prices={prices} />
        </Grid>
      )}
      {externalOutputs.length === 1 && (
        <Grid item xs={12}>
          <ExternalOutputDetails output={externalOutputs[0]} prices={prices} />
        </Grid>
      )}
      {changeOutputs.length > 0 &&
        changeOutputs.map((output, idx) => (
          <Grid item xs={12} key={idx}>
            <ChangeOutputDetails
              accountType={accountType}
              accountUUID={accountUUID}
              accountName={accountName}
              output={output}
              prices={prices}
              accountDetails={accountDetails}
            />
          </Grid>
        ))}
    </>
  );
};
