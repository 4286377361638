import React from "react";

import { satoshisToBitcoins } from "@caravan/bitcoin";
import { Icons } from "@unchained/component-library";

import { BatchSpendEntryType } from "Components/VaultsView/Show/WithdrawModal/types";
import { useMemoizedState } from "Redux/selectors/hooks";
import { useFeeRates } from "Shared/api/hooks/fees";
import { BITCOIND_MAX_FEE_SATS } from "Utils/constants";
import { formatCurrency } from "Utils/strings";

import { BATCH_DESTINATION_LIMIT, validateBatchSpendTableData } from "./functions";
import { BatchError } from "./types";

export const BatchValidationMessage = ({
  data,
  setBatchInvalid,
}: {
  data: BatchSpendEntryType[];
  setBatchInvalid: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const vault = useMemoizedState("vaults.vaultShow.vault");
  const { fast } = useFeeRates();

  const dataValidation = validateBatchSpendTableData(data, vault, fast);

  if (dataValidation.length > 0) {
    setBatchInvalid(true);
  } else {
    setBatchInvalid(false);
    return null;
  }

  const firstError = dataValidation.shift();
  let message = "";

  switch (firstError) {
    case BatchError.TOO_MANY_OUTPUTS:
      message = `Too many outputs (${data.length}). The maximum is ${BATCH_DESTINATION_LIMIT}. Please edit and re-upload your .csv file.`;
      break;

    case BatchError.EXCEEDS_BITCOIND_MAX_FEE:
      message = `This transaction as staged will exceed the maximum fee allowed (${satoshisToBitcoins(
        BITCOIND_MAX_FEE_SATS
      )} BTC)`;
      break;

    case BatchError.BALANCE_EXCEEDED:
      message = `Total plus estimated fee exceeds vault balance (${formatCurrency(
        vault.balance,
        8,
        false,
        true
      )} BTC). Please edit and re-upload your .csv file.`;
      break;

    case BatchError.ROW_ERRORS:
      message =
        "The following values (in red) were invalid. Please edit and re-upload your .csv file.";
      break;

    default:
      message = "There are errors in the file contents. Please edit and re-upload your .csv file.";
      break;
  }

  return (
    <div className="flex h-8 p-1 text-xs" data-testid="batch-validation-message">
      <span className="align-middle">
        <Icons.AlertCircle className="h-5 w-5 pl-1 text-red-700" />
      </span>
      <span className="align-middle">{message}</span>
    </div>
  );
};
