import React, { FC, ReactNode } from "react";

/* eslint-disable-next-line no-restricted-imports */
import { ArrowBack } from "@mui/icons-material";
import { Button, ButtonProps, ButtonType, IconProps, Loader } from "@unchained/component-library";
import cn from "classnames";

type InfoCell = {
  title: string;
  description: ReactNode;
  Icon: FC<IconProps>;
};

type ContainerType = {
  children: ReactNode;
  className?: string;
};

export const StepColumn = ({
  children,
  width,
  actions,
  loading,
  onBack,
  className,
}: {
  children: ReactNode;
  width?: "wide" | "narrow" | string;
  /**
   * Actions show at the bottom of the page.
   * On mobile, they become full-screen and stick to the bottom.
   * If an action has a buttonType of "submit", the step will become a form
   * with that onClick handler, so that it can be submitted on enter press.
   *  */
  actions?: [ButtonProps, ButtonProps] | [ButtonProps];
  loading?: boolean;
  onBack?: () => void;
  className?: string;
}) => {
  const widthClass =
    {
      wide: "max-w-2xl",
      narrow: "max-w-xl",
    }[width] || width;

  if (loading) return <Loader />;
  const submitAction = actions && actions.find(a => a.buttonType === "submit");

  const content = (
    <div
      className={cn(
        "flex h-full flex-col justify-start pt-0 sm:pt-12",
        actions
          ? // If there are actions, we need to add padding (per action button) to the bottom of the page
            ["max-sm:mb-8", "max-sm:mb-16", "max-sm:mb-32", "max-sm:mb-48"][actions.length]
          : "max-sm:mb-8",
        className
      )}
    >
      {onBack && (
        <Button
          className="ml-7 justify-start"
          type="text"
          onClick={onBack}
          startIcon={<ArrowBack />}
        >
          Back
        </Button>
      )}
      <div className={cn("flex w-full flex-col gap-7 p-6 max-sm:max-w-none", widthClass)}>
        {children}
      </div>
      {actions ? (
        <div className="z-10 flex flex-col-reverse gap-4 px-6 pb-6">
          {actions.map((action, ind) => (
            <Button
              {...action}
              // We attach submit actions to the form, and so remove them from the button
              // so they aren't called duplicatively.
              onClick={action.buttonType === "submit" ? undefined : action.onClick}
              fullWidth
              type={action.type || (["primary", "text"] as ButtonType[])[ind]}
              className={action.className}
              key={ind}
              data-testid={`step-action-${ind}`}
            />
          ))}
        </div>
      ) : null}
    </div>
  );

  // If the step is passed a submit action, it should be a form,
  // so that enter submits the form
  if (submitAction) {
    return (
      <form
        className={className}
        onSubmit={e => {
          e.preventDefault();
          (submitAction.onClick as (e: React.FormEvent<HTMLFormElement>) => void)(e);
        }}
      >
        {content}
      </form>
    );
  } else {
    return <div className={className}>{content}</div>;
  }
};

export const StepTop = ({ children }: ContainerType) => (
  <div className="flex w-full flex-col items-start gap-7">{children}</div>
);

export const StepDivider = () => <div className="my-10 h-px w-full bg-gray-200" />;

export const StepSplitBottom = ({ children }: ContainerType) => (
  <div className="grid w-full grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-10">{children}</div>
);

export const StepInfoCell = ({ title, description, Icon }: InfoCell) => (
  <div className="flex flex-col items-start gap-2">
    <Icon className="mb-1 text-primary-600" />
    <p className="text-sm font-semi text-gray-900">{title}</p>
    <p className="text-sm font-reg text-gray-500">{description}</p>
  </div>
);

export const StepTitle = ({ children, className }: ContainerType) => (
  <h1 className={cn("text-d-xs font-semi", className)}>{children}</h1>
);

export const StepDescription = ({ children, className }: ContainerType) => (
  <div className={cn("prose", className)}>{children}</div>
);
