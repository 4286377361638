import React from "react";

/* eslint-disable-next-line no-restricted-imports */
import { Business as OrgIcon, Person as UserIcon } from "@mui/icons-material";
import PropTypes from "prop-types";

import { Link } from "Components/Link";
import { orgOrUserAdminURL, userURL } from "Components/Shared/Elements/Links";

import styles from "./AccountNameCell.module.scss";
import { SummaryTableCell } from "./SummaryTableCell";

export const OwnerNameCell = ({ product, isPersonal }) => {
  const getName = () => {
    return isPersonal ? product.owner.name : product.name;
  };

  const getIcon = () => {
    return isPersonal ? <UserIcon /> : <OrgIcon />;
  };

  const getUrl = () => {
    return isPersonal ? userURL(product.owner) : orgOrUserAdminURL(product);
  };

  return (
    <SummaryTableCell>
      <div className={styles.container}>
        {getIcon()}
        <Link to={getUrl()} target="_blank">
          <span className={styles.name}>{getName()}</span>
        </Link>
      </div>
    </SummaryTableCell>
  );
};

OwnerNameCell.propTypes = {
  product: PropTypes.shape().isRequired,
  isPersonal: PropTypes.bool,
};
