import { ReactNode } from "react";

/* eslint-disable-next-line no-restricted-imports */
import { ExpandMore } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import cn from "classnames";

import { useNavigationDispatch, useNavigationState } from "Contexts/Navigation";
import { CollapsibleSection as CollapsibleSectionType } from "Contexts/Navigation/NavigationContext";

import { NavLink } from "./NavLink";

export const CollapsibleSection = ({
  startIcon,
  title,
  items,
  section,
  disableWithOptionalNote,
}: {
  startIcon: ReactNode;
  title: string;
  items: { to: string; children: ReactNode }[];
  section: CollapsibleSectionType;
  disableWithOptionalNote?: boolean | string;
}) => {
  const subItemIsActive = items.some(item => item.to === window.location.pathname);
  const { setOpenSection } = useNavigationDispatch();
  const { openSection } = useNavigationState();

  const open = openSection === section;
  const setOpen = (open: boolean) => {
    setOpenSection(open ? section : null);
  };
  return (
    <>
      <NavLink
        onClick={() => setOpen(!open)}
        startIcon={startIcon}
        disableWithOptionalNote={disableWithOptionalNote}
        endIcon={<ExpandMore className={cn("transition-all", open && "rotate-180")} />}
        active={subItemIsActive && !open}
        closeDrawerOnClick={false}
      >
        {title}
      </NavLink>
      <Collapse in={open} className="overflow-y-auto">
        {items.map(item => (
          <NavLink to={item.to} key={item.to} child>
            {item.children}
          </NavLink>
        ))}
      </Collapse>
    </>
  );
};
