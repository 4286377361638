import { ReactNode } from "react";

// eslint-disable-next-line no-restricted-imports
import { MonetizationOnOutlined } from "@mui/icons-material";
import { Icons } from "@unchained/component-library";
import cn from "classnames";
import { useSelector } from "react-redux";

import fullLogoWhite from "Assets/images/FullLogoWhite.svg";
import signatureLogoWhite from "Assets/images/SignatureLogoWhite.svg";
import { Link } from "Components/Link";
import { useCashBalanceEnabled } from "Components/PaymentMethods/hooks";
import { BuyAndSellBitcoinButtons } from "Components/TradingDesk/components/BuyAndSellBitcoinButtons";
import { HighlightOnboardingIncompleteCardButton } from "Components/TradingDesk/components/HighlightOnboardingIncompleteCardButton";
import { getBuyBitcoinDefaultVaultFromVault } from "Components/TradingDesk/helpers";
import {
  useIsBuyBitcoinFeatureEnabled,
  useTradingOnboardingCardStatus,
} from "Components/TradingDesk/hooks";
import * as UCIcon from "Components/UCIcon";
import { useNavigationDispatch, useNavigationState } from "Contexts/Navigation";
import { CollapsibleSection as CollapsibleSectionType } from "Contexts/Navigation/NavigationContext";
import { isLensable } from "Redux/selectors/hooks";
import { useIraOrg, withAccount } from "Shared/api";
import { featureOn } from "Utils/config";
import { needsToPay } from "Utils/orgState";

import { AccountSwitcher } from "./AccountSwitcher";
import { CollapsibleSection } from "./CollapsibleSection";
import { FundIraButton } from "./FundIraButton";
import { NavLink } from "./NavLink";
import { Notifications } from "./Notifications";
import { SupportPinButton } from "./SupportPin";
import { useAccountSettingsLinks } from "./useAccountSettingsLinks";

type Props = {
  mobile?: boolean;
  className?: string;
  id?: string;
};

interface NavLinkOrSection {
  to?: string;
  children: string;
  startIcon: ReactNode;
  show: boolean;
  subItems?: { to: string; children: string }[];
  section: CollapsibleSectionType;
  disableWithOptionalNote?: boolean | string;
}

export const OldSidebar = withAccount<Props>(props => {
  const { mobile = false, className = "", id, user, currentOrg: org } = props;

  const { openSection } = useNavigationState();
  const { setOpenSection } = useNavigationDispatch();

  const { showTradingOnboardingIncompleteCard } = useTradingOnboardingCardStatus(false, org);
  const { isTradingFeatureAvailable } = useIsBuyBitcoinFeatureEnabled();
  const defaultVault = useSelector(getBuyBitcoinDefaultVaultFromVault);
  const getIra = useIraOrg(org?.uuid, {
    enabled: org?.uuid && org.account_type === "ira",
  });
  const { isCashBalanceAllowed, cashBalanceTitle } = useCashBalanceEnabled();

  const { type, account_type: accountType } = org;

  const lockedForPayment = needsToPay(org);

  const lensable = isLensable(org);

  const isAdmin = type === "unchained";
  const isArbiter = type === "arbiter";
  const isClient = type === "client";
  const isDelegate = type === "delegate";

  // Clicking any link in the nav should close any collapsible section other than admin
  const closeNonAdminSections = () => {
    setOpenSection(openSection === "admin" ? "admin" : null);
  };

  const home = "/home";

  const isLender = (org as unknown as { lender?: boolean }).lender;

  const isSignature = user?.signature && org.account_type !== "business";

  const disableLinksForIraAgreement =
    org.account_type === "ira" && !getIra.data?.org?.iraPlan?.fortisAgreementAcceptedAt;

  const disabledNote =
    disableLinksForIraAgreement && "Consent to your IRA agreement to access this feature.";

  const { links: accountLinks } = useAccountSettingsLinks();

  const navLinks = (
    [
      {
        children: "Keys",
        startIcon: <UCIcon.Keys />,
        show: lensable && !lockedForPayment,
        to: "/account_keys",
      },
      {
        to: "/vaults",
        children: "Vaults",
        startIcon: <UCIcon.Vaults />,
        show: lensable && !lockedForPayment && !isArbiter,
      },
      {
        to: "/loans",
        children: "Loans",
        startIcon: <UCIcon.Loans />,
        show: lensable && isClient && !isLender && accountType !== "ira" && !lockedForPayment,
      },
      {
        to: "/connections",
        show: true,
        startIcon: <Icons.Globe />,
        children: "Connections",
      },
      {
        to: "/cash-balance",
        children: cashBalanceTitle,
        startIcon: <MonetizationOnOutlined />,
        show: isCashBalanceAllowed,
      },
      {
        children: "Account settings",
        startIcon: <UCIcon.Settings />,
        subItems: accountLinks,
        show: lensable && !!accountLinks.length,
      },
    ] as NavLinkOrSection[]
  )
    .filter(link => link.show)
    .map(link => ({
      ...link,
      onClick: closeNonAdminSections,
      disableWithOptionalNote: disabledNote,
    }));

  const TradeButton = () => {
    const isLoadingOrgFeatures = !org.all_features;
    const isSellBitcoinFeatureFlagEnabled: boolean = featureOn("sell_bitcoin", org);

    const buyBitcoinStyles =
      isSellBitcoinFeatureFlagEnabled && !isLoadingOrgFeatures
        ? "btn btn-secondary hover:!bg-primary-600 hover:!border-primary-600"
        : "!bg-primary-600 hover:!border-primary-600 hover:!bg-white hover:!text-primary-600";

    return showTradingOnboardingIncompleteCard ? (
      <HighlightOnboardingIncompleteCardButton
        currentOrg={org}
        fullWidth
        mobile={mobile}
        disabled={disableLinksForIraAgreement}
      />
    ) : (
      <BuyAndSellBitcoinButtons
        showDisableButtonOnNotEnabled={isTradingFeatureAvailable && disableLinksForIraAgreement}
        isTradingFeatureAvailable={isTradingFeatureAvailable && !disableLinksForIraAgreement}
        defaultVault={defaultVault}
        fullWidth
        className={disableLinksForIraAgreement ? undefined : buyBitcoinStyles}
        isSellBitcoinFeatureFlagEnabled={
          isLoadingOrgFeatures ? "LOADING" : isSellBitcoinFeatureFlagEnabled
        }
      />
    );
  };

  const adminLinks = [
    { to: "/users", children: "Users" },
    { to: "/orgs", children: "Orgs" },
    { to: "/admin/orgs/trusts", children: "Trusts" },
    { to: "/orgs/ira/admin", children: "IRAs" },
    { to: "/loans/admin", children: "Loans" },
    { to: "/loans/payments/due", children: "Loan payments" },
    { to: "/loans/collateral-sale-requests", children: "Collateral sales" },
    { to: "/vaults/admin", children: "Vaults" },
    { to: "/rekeys", children: "Rekeys" },
    { to: "/trading", children: "Trading" },
    { to: "/usd_activity", children: "USD activity" },
    { to: "/btc_transaction_requests", children: "TX reqs" },
    { to: "/2fa/resets", children: "2FA resets" },
    { to: "/wallet", children: "Wallet" },
    { to: "/features", children: "Features" },
    { to: "/admin/bundles", children: "Product Bundles" },
  ].map(link => ({ ...link, onClick: closeNonAdminSections }));

  const arbiterLinks = [
    { to: "/arbiter/orgs", children: "Clients" },
    { to: "/arbiter/loans", children: "Loans" },
    { to: "/arbiter/vaults", children: "Vaults" },
  ].map(link => ({ ...link, onClick: closeNonAdminSections }));

  const delegateLinks = [{ to: "/orgs/delegated-custody/clients", children: "Clients" }];

  return (
    <div
      id={id}
      className={cn(
        className,
        "relative flex h-screen min-w-nav flex-col items-start justify-between bg-primary-900 py-5 transition-transform duration-700"
      )}
      data-testid={mobile ? "mobile-nav" : "regular-nav"}
    >
      {/* Upper half - stuck to top of nav */}
      <div
        className={cn(
          "relative flex h-full w-full flex-col justify-between overflow-y-auto overflow-x-hidden px-4",
          {
            "border-b-2 border-b-primary-700 pb-4": openSection === "admin" && isAdmin,
          }
        )}
      >
        <div className="mb-3 flex items-center justify-between">
          <Link
            to={home}
            className="flex w-full pb-3 pl-[5px] pt-3"
            onClick={closeNonAdminSections}
            data-testid="home-link"
          >
            <img
              src={isSignature ? signatureLogoWhite : fullLogoWhite}
              className="block w-44"
              alt="Full logo white"
            />
          </Link>
          {/* Notification menu trigger and popover */}
          <Notifications mobile={mobile} disabled={disableLinksForIraAgreement} />
        </div>

        {/* Menu for switching between orgs */}
        <AccountSwitcher mobile={mobile} className="mb-3" />

        <div className="h-full overflow-y-auto">
          <div className="relative overflow-y-auto pb-8">
            {navLinks.map(link =>
              link.to ? (
                <NavLink {...link} key={link.to} />
              ) : (
                <CollapsibleSection
                  {...link}
                  title={link.children}
                  items={link.subItems}
                  key={link.children}
                />
              )
            )}

            {lensable ? (
              <>
                {isAdmin ? (
                  <CollapsibleSection
                    title="Admin"
                    startIcon={<UCIcon.Admin />}
                    items={adminLinks}
                    section="admin"
                  />
                ) : null}

                {isArbiter ? (
                  <CollapsibleSection
                    title="Arbiter"
                    startIcon={<UCIcon.Admin />}
                    items={arbiterLinks}
                    section="arbiter"
                  />
                ) : null}
                {isDelegate && (
                  <CollapsibleSection
                    title="Delegated custody"
                    startIcon={<UCIcon.Admin />}
                    items={delegateLinks}
                    section="delegate"
                  />
                )}
              </>
            ) : null}
          </div>
        </div>
        {/* Gradient to indicate vertically overlapped content in the top section */}
        <div
          className="pointer-events-none absolute bottom-0 h-12 w-full"
          style={{
            background: "linear-gradient(180deg, rgba(14, 46, 94, 0) 0%, #0E2E5E 100%)",
          }}
        />
      </div>

      {/* Lower half - stuck to bottom of nav */}
      <div className="mt-6 flex w-full flex-col bg-primary-900 transition-colors">
        <div className="px-4">
          <div className="flex flex-col gap-4">
            <div>
              <NavLink
                to="/settings"
                startIcon={<UCIcon.UserSquare />}
                onClick={closeNonAdminSections}
              >
                User info
              </NavLink>
              <NavLink
                to="/support"
                startIcon={<UCIcon.Support />}
                onClick={closeNonAdminSections}
                className="max-w-full"
                linkClassName="w-full"
              >
                Support
                <SupportPinButton />
              </NavLink>
            </div>

            {org.account_type === "ira" && <FundIraButton disabled={disableLinksForIraAgreement} />}
            <TradeButton />
          </div>
        </div>
      </div>
    </div>
  );
});
