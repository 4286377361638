/* eslint-disable-next-line no-restricted-imports */
import { Check, Clear } from "@mui/icons-material";
import { Chip } from "@unchained/component-library";

import { LiveVote } from "Shared/api/changeProposalApi";
import { readableTime } from "Utils/time";

const StatusChip = ({ status }: { status: "approve" | "deny" }) => {
  switch (status) {
    case "approve":
      return <Chip type="success" icon={<Check />} label="Approved"></Chip>;
    case "deny":
      return <Chip type="error" label="Denied" icon={<Clear />}></Chip>;
    default:
      return <span>________</span>;
  }
};

export const VotesTable = ({ liveVotes, minApprovals, showEmptyRows }) => {
  const cellClasses = "pr-4";
  const voteDisplay = [...liveVotes].sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));
  const blankRowCount = Math.max(
    minApprovals - liveVotes.filter(row => row.vote === "approve").length,
    0
  );
  if (showEmptyRows) {
    for (let i = 0; i < blankRowCount; i++) {
      voteDisplay.push({
        user: { email: "___________________" },
        ipAddress: "______________",
        vote: undefined,
      });
    }
  }

  return (
    <div className="min-w-[700px]">
      <h4>Approvers</h4>
      <table>
        <thead>
          <tr>
            <th className={cellClasses}>#</th>
            <th className={cellClasses}>Email</th>
            <th className={cellClasses}>Timestamp</th>
            <th className={cellClasses}>IP Address</th>
            <th className={cellClasses}>Status</th>
          </tr>
        </thead>
        <tbody className="text-gray-700">
          {voteDisplay.map((vote: LiveVote, index: number) => (
            <tr key={index}>
              <td className={cellClasses}>{index + 1}.</td>
              <td className={cellClasses}>{vote.user.email || "User no longer exists"}</td>
              <td className={cellClasses}>
                {vote.createdAt ? readableTime(vote.createdAt, true) : "________________"}
              </td>
              <td className={cellClasses}>{vote.ipAddress}</td>
              <td className={cellClasses}>
                <StatusChip status={vote.vote} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
