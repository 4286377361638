import React, { useContext, useEffect, useState } from "react";

import { SigningKey } from "Components/Transactions/ConfirmSigningKeys";
import { SigningKeySelection } from "Components/Transactions/SigningKeySelection";
import { WithdrawModalContext } from "Components/VaultsView/Show/WithdrawModal";
import { AccountKey } from "Components/VaultsView/types";
import { useMemoizedState } from "Redux/selectors/hooks";

import { isVerificationRequired } from "./functions";

const getInitializedSigningKeys = (vault: any, accountKeys: AccountKey[]) => {
  let signingKeys: SigningKey[] = [];

  try {
    // Sort accountKeys by the preferred order of roles
    const rolePriority = { client: 0, arbiter: 1, delegate: 2, unchained: 3 };
    const sortedAccountKeys = accountKeys.sort(
      (keyA, keyB) => (rolePriority[keyA.role] ?? 4) - (rolePriority[keyB.role] ?? 4)
    );

    // We're dealing with an "any" vault here. Try stuff in case it breaks
    if (vault.wallet_type === "multiorg") {
      const clientAccountKey = sortedAccountKeys.find(key => key.role === "client");
      const unchainedAccountKey = sortedAccountKeys.find(key => key.role === "unchained");
      const arbiterAccountKey = sortedAccountKeys.find(key => key.role === "arbiter");
      const delegateAccountKeys = sortedAccountKeys.filter(key => key.role === "delegate");
      if (arbiterAccountKey) {
        // unchained always 2nd key
        signingKeys[0] = { uuid: clientAccountKey.uuid, active: true };
        signingKeys[1] = { uuid: unchainedAccountKey.uuid, active: false };
        signingKeys[2] = { uuid: arbiterAccountKey.uuid, active: false };
      } else if (clientAccountKey && delegateAccountKeys.length === 1) {
        // in this case have 1 client key and 1 unchained key and 1 delegate key
        signingKeys[0] = { uuid: clientAccountKey.uuid, active: true };
        signingKeys[1] = { uuid: unchainedAccountKey.uuid, active: false };
        signingKeys[2] = { uuid: delegateAccountKeys[0].uuid, active: false };
      } else if (delegateAccountKeys.length === 2) {
        // in this case have 2 delegate keys and 1 unchained key (we put unchained first)
        signingKeys[0] = { uuid: unchainedAccountKey.uuid, active: false };
        signingKeys[1] = { uuid: delegateAccountKeys[0].uuid, active: false };
        signingKeys[2] = { uuid: delegateAccountKeys[1].uuid, active: false };
      } else {
        throw Error("Unexpected combination of keys for multi-institutional vault");
      }
    } else {
      for (const [index, key] of sortedAccountKeys.entries()) {
        const uuid = key.uuid;
        let active: boolean;
        if (key.role === "client") {
          active = !vault.preferred_account_key_uuid || vault.preferred_account_key_uuid === uuid;
        } else {
          // unchained key
          active = !!vault.preferred_account_key_uuid;
        }
        signingKeys[index] = { uuid, active };
      }
    }
  } catch (err) {
    console.error(err, "Couldn't initialize signing keys for selection");
  }

  return signingKeys;
};

export const KeySelect = () => {
  const vault = useMemoizedState("vaults.vaultShow.vault");
  const { uuid: orgUUID } = useMemoizedState("account.orgs.current");
  const { amount, signingKeys, setState } = useContext(WithdrawModalContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [canShowError, setCanShowError] = useState(false);

  const accountKeys: AccountKey[] = vault.current_account_keys;

  useEffect(() => {
    // Initializes and selects default keys

    if (accountKeys?.length === 0) {
      return;
    }

    const signingKeys = getInitializedSigningKeys(vault, accountKeys);

    setState({ signingKeys });
  }, [vault, accountKeys, setState]);

  useEffect(() => {
    // Validation. Enables Next if two keys are selected.

    const selectedKeys = signingKeys.filter(key => key.active);

    // Case: Less than two
    if (!selectedKeys || selectedKeys.length < 2) {
      setErrorMessage("Please choose two keys to sign with.");
      return;
    }

    // Case: More than two
    if (selectedKeys.length > 2) {
      setCanShowError(true); // Don't show errors until has firstly selected 2 different keys.
      setErrorMessage("Please choose only two keys to sign with.");
      return;
    }

    setErrorMessage("");
    setState({ nextDisabled: false });
  }, [signingKeys, setState]);

  const verificationRequired = isVerificationRequired(amount, vault, accountKeys, signingKeys);

  return (
    <>
      <div className="pt-2">
        {vault.current_account_keys && signingKeys.length === 3 && (
          <SigningKeySelection
            orgUUID={orgUUID}
            accountKeys={vault.current_account_keys}
            key1={signingKeys[0]}
            key2={signingKeys[1]}
            key3={signingKeys[2]}
            handleKeyClick={(keyIndex: number, isActive: boolean) => {
              const newSigningKeys = [...signingKeys];
              newSigningKeys[keyIndex] = { ...newSigningKeys[keyIndex], active: !isActive };
              setState({ signingKeys: newSigningKeys });
            }}
          />
        )}
      </div>

      {verificationRequired && (
        <p className="mb-0 mt-4 max-h-[1em] min-h-[1em] text-center leading-[1em] text-gray-300">
          <small>This vault transaction will require verification.</small>
        </p>
      )}

      <div className="mt-3 block min-h-[1.125rem] text-center text-xs text-red-500">
        {canShowError && errorMessage}
      </div>
    </>
  );
};
