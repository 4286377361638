import React from "react";

/* eslint-disable-next-line no-restricted-imports */
import { HelpOutline } from "@mui/icons-material";
import { Tooltip } from "@unchained/component-library";

import defaultLogo from "Assets/images/default_key.png";
import { logoMap } from "Components/Shared/Elements/Keys/logoMap";
import { KeyIcon3 } from "Components/Shared/Icons/KeyIcon3";
import { UnchainedLogo } from "Components/Shared/Icons/UnchainedLogo";

import styles from "./SigningKeyCard.module.scss";

type SigningKeyCardProps = {
  title: string;
  isUnchained: boolean;
  className?: string;
  isSigned?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  isDelegatedKey?: boolean;
  logoSlug?: string | null;
};
export const SigningKeyCard = ({
  title,
  isUnchained,
  className,
  isSigned = false,
  onClick,
  disabled,
  isDelegatedKey,
  logoSlug,
}: SigningKeyCardProps) => {
  const setKeyStyles = () => {
    const delegatedStyles = {
      upperBackground: "bg-gray-400",
      lowerBackground: "bg-gray-700",
      signedTextColor: "text-[#002851]",
    };

    const unchainedStyles = {
      upperBackground: "bg-[#0095D6]",
      lowerBackground: "bg-[#002851]",
      signedTextColor: "text-[#002851]",
    };
    const userStyles = {
      upperBackground: "bg-[#62B288]",
      lowerBackground: "bg-[#1E4842]",
      signedTextColor: "text-[#1E4842]",
    };

    if (isUnchained) {
      return unchainedStyles;
    } else if (isDelegatedKey) {
      return delegatedStyles;
    } else {
      return userStyles;
    }
  };

  const { upperBackground, lowerBackground, signedTextColor } = setKeyStyles();

  const delegatedKeyLogo = logoMap[logoSlug] ?? defaultLogo;

  const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!disabled && onClick) {
      onClick(e);
    }
  };

  const cursorType = !disabled && onClick ? "cursor-pointer" : "cursor-default";

  return (
    <div
      onClick={handleOnClick}
      className={`relative mb-2 h-[108px] w-[206px] ${cursorType} transition-shadow ${className}`}
    >
      {isSigned ? (
        <div
          className={`${styles.signingBox} absolute right-0 top-0 h-[54px] w-[54px] bg-[#E3E8EFE5] opacity-80`}
        >
          <p
            className={`rotate-45 text-[10px] font-bold ${signedTextColor}  relative left-3     top-4 opacity-100
`}
          >
            SIGNED
          </p>
        </div>
      ) : null}
      <div className={`h-4/6 w-full  rounded-t-lg  ${upperBackground} flex justify-between p-3`}>
        <KeyIcon3 />
        {isUnchained ? (
          <UnchainedLogo />
        ) : isDelegatedKey ? (
          <img
            data-testid="signing-key-card-delegated-key-logo"
            src={delegatedKeyLogo}
            alt={`${title} Logo`}
            className="h-8 w-8"
          />
        ) : null}
      </div>
      <div
        className={`flex h-2/6  w-full  rounded-b-lg ${lowerBackground} justify-between pb-[10] pl-3 pr-3 pt-[10px]`}
      >
        <p className="select-none truncate text-xs text-white">{title}</p>
        {isUnchained ? (
          <Tooltip
            arrow
            placement="top"
            compact
            content={
              "Unchained strives to sign transactions within one business day, however this is not guaranteed. "
            }
          >
            <HelpOutline className="text-lg text-[#0095D6]" />
          </Tooltip>
        ) : null}
      </div>
    </div>
  );
};
