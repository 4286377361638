import { ReactNode } from "react";

import {
  Button,
  ButtonProps,
  FloatingIcon,
  FloatingIconProps,
  Icons,
} from "@unchained/component-library";

type IconPageProps = {
  title: string;
  subtitle?: string | ReactNode;
  button?: ButtonProps;
  floatingIconProps?: Partial<FloatingIconProps>;
  textContent?: ReactNode;
  children?: ReactNode;
};
export const IconPage = ({
  title = "Congrats! Your transaction has been successfully processed.",
  subtitle,
  button,
  floatingIconProps = {},
  textContent,
  children,
}: IconPageProps) => {
  const iconProps = {
    color: "green",
    size: "lg",
    Icon: Icons.Checkmark,
    ...floatingIconProps,
  } as FloatingIconProps;
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <FloatingIcon {...iconProps} />
      <div className="flex max-w-2xl flex-col items-center text-center">
        <h1 className="mb-4 mt-8 text-d-md font-semi text-gray-900">{title}</h1>
        {subtitle ? <h2 className="text-md font-reg text-gray-600">{subtitle}</h2> : null}
        {textContent && (
          <div className="mt-8 flex max-w-md flex-col items-center rounded-sm bg-gray-50 px-12 py-6">
            <div className="prose text-center">{textContent}</div>
            {children}
          </div>
        )}
        {button ? <Button type="primary" className="mt-8 px-20 py-3" {...button} /> : null}
      </div>
    </div>
  );
};
