import { logoMap } from "Components/Shared/Elements/Keys/logoMap";

type KeyOwner = "client" | "unchained" | undefined;

/**
 * Maps the "role" field (deprecated in API v1) to the key owner type.
 * Returns the "role" as the key owner if it matches "client" or "unchained" and is not shared with the user.
 * For API v2, "ownerType" should be used instead of "role" for expanded values.
 * This function ensures compatibility with legacy components still relying on API v1.
 */
export const mapKeyRoleToKeyOwner = ({
  role,
  isSharedWithMe = false,
}: {
  role: string;
  isSharedWithMe?: boolean;
}): KeyOwner => {
  if ((!isSharedWithMe && role === "client") || role === "unchained") {
    return role;
  }
  return undefined;
};

/**
 * Build the key avatar props with logo for a delegate or arbiter key based on its role.
 */
export const keyAvatarProps = accountKey => {
  if (accountKey.role === "delegate" || accountKey.role === "arbiter") {
    return {
      avatarProps: {
        imageSource: logoMap[accountKey["logo_slug"]] || logoMap[accountKey["logoSlug"]],
        style: { backgroundColor: "#FFFFFF" },
      },
    };
  }
  return {};
};

interface AccountKey {
  role: "client" | "delegate" | "arbiter" | "unchained";
  owner: {
    uuid: string;
  };
}

export const useGetKeyTypeProps = ({ key, orgUuid }: { key: AccountKey; orgUuid: string }) => {
  const isShared = key.owner.uuid !== orgUuid;
  const ownerType = mapKeyRoleToKeyOwner({ role: key.role, isSharedWithMe: isShared });
  return {
    ownerType,
    isShared,
  };
};
