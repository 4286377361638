/* eslint-disable-next-line no-restricted-imports */
import { Info as InfoIcon } from "@mui/icons-material";

export const ProvideIraInfoNotification = ({ org }: { org: { uuid: string } }) => {
  return {
    to: `/orgs/ira/${org.uuid}`,
    title: `Resume IRA application`,
    Icon: InfoIcon,
  };
};
