import { Button, useBreakpoint } from "@unchained/component-library";

import { AppModalManager } from "Shared/components/Modals";

import { StepHeader } from "../components/StepHeader";
import { WithdrawalIraTypes } from "../types";

type CompleteFromStepProps = {
  selectedIraWithdrawalType?: WithdrawalIraTypes;
};
export const CompleteFormStep = ({ selectedIraWithdrawalType }: CompleteFromStepProps) => {
  const { widthIsBelow } = useBreakpoint();
  const isMobile = widthIsBelow("sm");
  const formWidth = isMobile ? "350px" : "600px";
  const height = isMobile ? "600px" : "90%";

  const Distribution = () => {
    return (
      <div className={`mt-8`} style={{ height: height }}>
        <div className=" h-full">
          <StepHeader title="Withdrawal method" isBackButtonShowing={true} className="ml-4" />
          <iframe
            src={"https://unchainedcapital.formstack.com/forms/ira_distribution"}
            title="Formstack Form"
            height="100%"
            width={formWidth}
          ></iframe>
        </div>
      </div>
    );
  };

  const OutboundTransfer = () => {
    return (
      <div className="max-w-md">
        <StepHeader title="Outbound transfer" isBackButtonShowing={true} />
        <div className="mt-2 ">
          <p className="text-md font-reg text-gray-700">
            To initiate an outbound IRA-to-IRA transfer please reach out to the receiving custodian
            to initiate the request.
          </p>
          <p className="mt-6 text-md font-reg text-gray-700">
            Note: If you’re looking to make an indirect rollover, please go back and proceed with
            the distribution option.
          </p>
          <p className="mt-6 text-md font-reg text-gray-700">
            If you have any questions please reach out to IRA@unchained.com
          </p>
        </div>

        <Button fullWidth onClick={AppModalManager.close} type="primary" className="mt-6">
          Close
        </Button>
      </div>
    );
  };

  return selectedIraWithdrawalType === WithdrawalIraTypes.DISTRIBUTION ? (
    <Distribution />
  ) : (
    <OutboundTransfer />
  );
};
