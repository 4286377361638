import React, { ReactNode, useContext } from "react";

import { Button, ButtonProps, Icons, WizardStepperContext } from "@unchained/component-library";

import unchainedTextLogo from "Assets/images/TextLogoDark.svg";
import {
  StepColumn,
  StepDescription,
  StepDivider,
  StepInfoCell,
  StepSplitBottom,
  StepTitle,
  StepTop,
} from "Components/Shared/Layouts/FullPageWizard";

/** A landing page for a "section" in an onboarding flow */
export const LandingPage = ({
  title,
  description,
  action,
  infoCellTitle = "What information will I need?",
  infoCellDescription,
  timeCellTitle = "How long does it take?",
  timeCellDescription,
}: {
  title: ReactNode;
  description: ReactNode;
  action?: Partial<ButtonProps>;
  infoCellTitle?: string;
  infoCellDescription: ReactNode;
  timeCellTitle?: string;
  timeCellDescription: ReactNode;
}) => {
  const { goToNext } = useContext(WizardStepperContext);
  const actionProps = {
    children: "Continue",
    onClick: goToNext,
    ...(action || {}),
  } as ButtonProps;
  return (
    <StepColumn width="wide">
      <StepTop>
        <img src={unchainedTextLogo} alt="unchained text logo" />
        <StepTitle>{title}</StepTitle>
        <StepDescription>{description}</StepDescription>
        <Button {...actionProps} className="px-6" />
      </StepTop>
      <StepDivider />
      <StepSplitBottom>
        <StepInfoCell title={infoCellTitle} description={infoCellDescription} Icon={Icons.Book} />
        <StepInfoCell title={timeCellTitle} description={timeCellDescription} Icon={Icons.Clock} />
      </StepSplitBottom>
    </StepColumn>
  );
};
