import { createContext, useState } from "react";

import { CamelCasedProperties, SetRequired } from "type-fest";

import { CompleteOrgAccountTypeEnum, GetAccount200 } from "Specs/v1/getAccount/200";
import { CompleteOrgPerson } from "Specs/v1/getOrg/200";

import { ShippingInfo } from "../(shared)/ShippingAddressStep";

export type GetSetObj<T> = {
  value: T;
  set: (value: T) => Promise<void>;
};

/**
 * Generates an object which contains a value and a promise-based setter for that value.
 * Used for tracking frontend-only choices in context.
 **/
export function useMakeGetSetObj<T>(startingVal?: T) {
  const [value, setValue] = useState(startingVal);
  return {
    value,
    set: (v: T) => Promise.resolve(setValue(v)),
  };
}

export type OnboardingInfo = SetRequired<
  CamelCasedProperties<GetAccount200>,
  "personalOrg" | "user" | "memberships"
> & {
  person: CompleteOrgPerson;
};

export type OnboardBasicContextType = OnboardingInfo & {
  accountType?: GetSetObj<CompleteOrgAccountTypeEnum>;
  from?: string;
  skipSSN: GetSetObj<boolean>;
  signatureUpgrade: GetSetObj<boolean>;
};

export const OnboardBasicContext = createContext<OnboardBasicContextType>(undefined);
