import { useBreakpoint } from "@unchained/component-library";

import { StepHeader } from "../components/StepHeader";
import { FundIraTypes } from "../types";

type CompleteFromStepProps = {
  selectedIraFundingType: FundIraTypes;
};
export const CompleteFormStep = ({ selectedIraFundingType }: CompleteFromStepProps) => {
  const getUrl = () => {
    if (selectedIraFundingType === FundIraTypes.ANNUAL_CONTRIBUTION) {
      return "https://unchainedcapital.formstack.com/forms/annual_contribution";
    } else if (selectedIraFundingType === FundIraTypes.IRA_TO_IRA_TRANSFER) {
      return "https://unchainedcapital.formstack.com/forms/ira_transfer";
    } else if (selectedIraFundingType === FundIraTypes.ROLLOVER) {
      return "https://unchainedcapital.formstack.com/forms/employer_plan_rollover";
    }
  };

  const { widthIsBelow } = useBreakpoint();
  const isMobile = widthIsBelow("sm");
  const formWidth = isMobile ? "350px" : "600px";
  const height = isMobile ? "600px" : "90%";

  return (
    <div className={`mt-8`} style={{ height: height }}>
      <div className=" flex h-full flex-col " style={{ width: "65%", maxWidth: "700px" }}>
        <StepHeader title="Funding method" isBackButtonShowing={true} className="ml-4" />
        <iframe src={getUrl()} title="Formstack Form" height="100%" width={formWidth}></iframe>
      </div>
    </div>
  );
};
