/* eslint-disable-next-line no-restricted-imports */
import { Delete } from "@mui/icons-material";
import { Button } from "@unchained/component-library";
import { useSelector } from "react-redux";

import { currentOrgSelector } from "Redux/selectors";
import { CloseAccountModal } from "Routes/settings/(MyAccounts)/CloseAccountModal";
import { useGetOrg } from "Shared/api/hooks";
import { AppModalManager } from "Shared/components/Modals/AppModalManager";
import { CompleteOrg } from "Specs/v1/getAccount/200";

export const OrgDelete = () => {
  const { uuid } = useSelector(currentOrgSelector);
  const orgQuery = useGetOrg(uuid);

  if (orgQuery.isLoading || orgQuery.isError) return null;

  const org = orgQuery.data;

  const openCloseAccountModal = () => {
    AppModalManager.open(() => <CloseAccountModal org={org as CompleteOrg} />);
  };

  return (
    <div>
      <h3 className="my-4">Close account</h3>
      <p>Get information on how to close this account.</p>
      <div className="mt-4 flex flex-row items-start">
        <Button
          endIcon={<Delete key="delete-icon" />}
          type="destructive"
          onClick={openCloseAccountModal}
          className="mr-8 h-fit w-fit"
        >
          Close account
        </Button>
      </div>
    </div>
  );
};
