import { Button } from "@unchained/component-library";

import { FundIRAStepperWizard } from "Components/IRAs/FundIra/FundIraStepper";
import { useCurrentOrgDeprecated } from "Redux/selectors/hooks";
import { useGetOrgVaults } from "Shared/api";
import { useIraOrg } from "Shared/api/hooks";
import { AppModalManager } from "Shared/components/Modals";

import { NoVaultsIraModal } from "./NoVaultsIraModal";

type FundIraButtonProps = {
  disabled?: boolean;
};
export const FundIraButton = ({ disabled }: FundIraButtonProps) => {
  const org = useCurrentOrgDeprecated();
  const getVaults = useGetOrgVaults(org.uuid);

  const iraOrgQuery = useIraOrg(org.uuid, {
    enabled: !!org.uuid,
  });

  if (org.state !== "live") return null;

  const handleClick = () => {
    const vaults = getVaults?.data || {};
    const isShowVaultModal = Object.keys(vaults).length === 0;
    if (isShowVaultModal) {
      AppModalManager.open(NoVaultsIraModal);
    } else {
      AppModalManager.open(() => {
        return <FundIRAStepperWizard />;
      });
    }
  };

  return (
    <Button
      disabled={disabled || getVaults.isLoading}
      onClick={handleClick}
      fullWidth
      type="darkSecondary"
    >
      Fund
    </Button>
  );
};
