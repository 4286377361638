import cn from "classnames";

import { Link } from "Components/Link";

type Props = { mobile?: boolean; className?: string; id?: string };

export const NewSidebar = ({ mobile, className, id }: Props) => {
  return (
    <div
      id={id}
      className={cn(
        className,
        "relative flex h-screen min-w-nav flex-col items-start justify-between border-r border-r-gray-300 bg-gray-50 py-5 transition-transform duration-700"
      )}
      data-testid={mobile ? "mobile-sidebar" : "regular-sidebar"}
    >
      <Link to="/logout">Sign out</Link>
    </div>
  );
};
