import { Dispatch, SetStateAction, useContext, useState } from "react";

import { PaymentIntent } from "@stripe/stripe-js";
import {
  Banner,
  ButtonProps,
  formatDollars,
  Loader,
  RadioButton,
  RadioGroup,
} from "@unchained/component-library";

import { useNavigate } from "Components/Link";
import { StepColumn } from "Components/Shared/Layouts/FullPageWizard";
import { useLoadingContext } from "Contexts/LoadingContext";
import { CheckoutHeader } from "Routes/inheritance/(shared)/CheckoutHeader";
import { InvoiceReceiptShow } from "Routes/invoices/(shared)/InvoiceReceiptShow";
import { FailedPayment } from "Routes/invoices/(shared)/PaymentFinalized";
import { Shipping } from "Routes/invoices/(shared)/ShippingAddressShow";
import { PaymentMethodSelection } from "Routes/invoices/[uuid]/(steps)/CheckoutStep";
import { useOrgPayingCustomer } from "Shared/api";
import { Invoice, INVOICE_STATUS, InvoiceAPI, SubscriptionAPI } from "Shared/api/v2";
import { usePurchasables } from "Shared/api/v2/hooks/purchasables";
import { ACTIVE_SIGNATURE_SKUS } from "Shared/api/v2/invoices";
import { useEasyToasts } from "Utils/toasts";

import { SignatureUpgradeContext } from "./SignatureUpgradeStepper";

const { FAILED, SETTLED, COMPLETED, PENDING, CANCELED } = INVOICE_STATUS;

const intervalAdverb = {
  month: "monthly",
  quarter: "quarterly",
  year: "annually",
};

const monthlyDenominator = {
  month: 1,
  quarter: 3,
  year: 12,
};

/*
 * Checkout page for invoices (invoice uuid in route).
 * Collects credit card information and processes payment.
 * Currently used for account annual fee + CO checkout.
 */
export const SignatureUpgradeCheckout = ({
  invoice,
  setSelectedSignatureSku,
}: {
  invoice: Invoice;
  setSelectedSignatureSku: Dispatch<SetStateAction<string>>;
}) => {
  const [paymentStatus, setPaymentStatus] = useState<PaymentIntent.Status | "failed">();
  const loading = useLoadingContext();
  const { from, shippingInfo, paymentProcessing } = useContext(SignatureUpgradeContext);
  const navigate = useNavigate();

  const { showErrorToast, showApiSuccessToast } = useEasyToasts();

  const payingCustomerQuery = useOrgPayingCustomer(invoice.orgId);
  const purchasablesQuery = usePurchasables();
  const signatureIntervals = purchasablesQuery.data?.purchasables
    ?.filter(p => ACTIVE_SIGNATURE_SKUS.includes(p.sku as any))
    .sort((a, b) => parseFloat(b.usdPrice) - parseFloat(a.usdPrice));

  const existingPaymentMethods = payingCustomerQuery.data?.paymentMethods || [];
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(undefined);
  const [additionalDiscount, setAdditionalDiscount] = useState(undefined);

  const applyCouponCode = async (couponCode: string) => {
    try {
      const resp = await InvoiceAPI.ValidateCouponCode(couponCode);
      if (resp.usdAmount) {
        setAdditionalDiscount({ title: couponCode, usdAmount: parseFloat(resp.usdAmount) / 100 });
      } else if (resp.percentage) {
        setAdditionalDiscount({ title: couponCode, percentage: parseFloat(resp.percentage) / 100 });
      }
    } catch (err) {
      showErrorToast(err, { title: "Invalid coupon code. Please try again." });
    }
    return;
  };

  const handleIntervalChange = e => {
    const sku = e.target.value;
    setSelectedSignatureSku(sku);
  };

  const onError = (err: unknown) => {
    console.error(err);
    showErrorToast("Something went wrong. Please try again.");
  };

  const handlePayWithExistingCard = async () => {
    setPaymentStatus("processing");
    paymentProcessing.set(true);

    try {
      const sigSub = SubscriptionAPI.SubscribeToSignature(
        selectedPaymentMethod,
        shippingInfo.value,
        invoice.lineItems[0].sku,
        additionalDiscount?.title
      );

      navigate(`/invoices/${(await sigSub).invoices[0].id}${from ? `?from=${from}` : ""}`);
    } catch (err) {
      handleError(err);
    }
  };

  const getActions = (): [ButtonProps, ButtonProps] | [ButtonProps] => {
    if (paymentStatus === "failed" || invoice.status === FAILED) {
      return [
        {
          children: "Try again",
          type: "secondary",
          onClick: handleTryAgain,
        },
        {
          children: "Exit",
          type: "text",
          to: from || "/home",
        },
      ];
    }
    return [
      {
        children: "Complete purchase",
        onClick: handlePayWithExistingCard,
        disabled: !selectedPaymentMethod || paymentStatus === "processing",
      },
    ];
  };

  const handleTryAgain = () => setPaymentStatus(undefined);
  const handleError = (err: unknown) => {
    setPaymentStatus("failed");
    paymentProcessing.set(false);
    onError(err);
  };

  const renderPaymentSection = () => {
    if (paymentStatus === "failed" || invoice.status === FAILED || invoice.status === CANCELED) {
      return <FailedPayment />;
    }

    return payingCustomerQuery.isLoading ? (
      <div className="h-48 p-8">
        <Loader />
      </div>
    ) : (
      <PaymentMethodSelection
        paymentProcessing={paymentProcessing.value}
        payingCustomerQuery={payingCustomerQuery}
        selectedPaymentMethod={selectedPaymentMethod}
        setSelectedPaymentMethod={setSelectedPaymentMethod}
        existingPaymentMethods={existingPaymentMethods}
        handleError={handleError}
        invoice={invoice}
      />
    );
  };

  return (
    <div className="max-w-4xl">
      <StepColumn width="full" className="pb-16" actions={getActions()} loading={loading.value}>
        <CheckoutHeader
          title="Payment"
          subtitle={
            "Please select your subscription frequency and enter your payment information below"
          }
        />
        <div className="flex min-h-[36rem] flex-col gap-8 lg:flex-row">
          <div className="flex w-96 flex-1 flex-col">
            <InvoiceReceiptShow
              invoice={invoice}
              applyCouponCode={!paymentProcessing.value ? applyCouponCode : undefined}
              additionalDiscount={additionalDiscount}
            />
            <Banner
              title="Your subscription includes 2 hardware devices"
              type="success"
              className="mt-4"
            >
              They will be shipped to the address below.
            </Banner>
            {shippingInfo?.value && (
              <Shipping name={shippingInfo.value.name} address={shippingInfo.value.address} />
            )}
          </div>

          <div className="w-96 flex-1">
            <h2>Subscription frequency</h2>
            {!signatureIntervals?.length ? (
              <div className="h-48 p-8">
                <Loader />
              </div>
            ) : (
              <div>
                <RadioGroup
                  name="interval"
                  className="mt-2 flex flex-col gap-4"
                  onChange={handleIntervalChange}
                  value={invoice.lineItems[0].sku}
                >
                  {signatureIntervals.map(o => (
                    <RadioButton
                      key={o.sku}
                      value={o.sku}
                      label={
                        <div>
                          <div className="text-md font-med text-gray-700">
                            Pay {intervalAdverb[o.subscriptionInterval]}{" "}
                            <span className="text-md font-reg text-gray-600">
                              {formatDollars(
                                Math.round(
                                  parseFloat(o.usdPrice) /
                                    monthlyDenominator[o.subscriptionInterval]
                                )
                              )}
                              /month
                            </span>
                          </div>
                          <div className="text-md font-reg text-gray-600">
                            {formatDollars(o.usdPrice)}/{o.subscriptionInterval} | Cancel anytime
                          </div>
                        </div>
                      }
                    ></RadioButton>
                  ))}
                </RadioGroup>
              </div>
            )}

            <h2 className="mb-4 mt-6 text-gray-900">Payment information</h2>

            {renderPaymentSection()}
          </div>
        </div>
      </StepColumn>
    </div>
  );
};
