import { useContext } from "react";

import { FloatingIcon, Icons, ModalContent, ModalFooter } from "@unchained/component-library";

import { ConfirmOnDeviceContext } from "../ConfirmOnDeviceWizard";

export const SuccessStep = () => {
  const { onDone } = useContext(ConfirmOnDeviceContext);
  return (
    <div className="items-center text-center">
      <ModalContent className="mt-0">
        <div className="flex justify-center">
          <FloatingIcon Icon={Icons.Checkmark} color="green" size="md" />
        </div>
        <h2>Confirmed!</h2>
        <p className="text-sm text-gray-500">
          Your device recognized this address and is safe to use.
        </p>
      </ModalContent>
      <ModalFooter actions={[{ children: "Done", onClick: onDone }]}></ModalFooter>
    </div>
  );
};
