import { VaultsList200, VaultSummary200 } from "Specs/v2";
import { VaultsListQueryParams } from "Specs/v2/vaultsList/params/query";

import { ApiType } from "../apiType";

export const VaultAPIFactory = (API: ApiType) => ({
  async GetVaults(params?: VaultsListQueryParams) {
    return (await API.Get<VaultsList200>(`/vaults`, params)).data;
  },
  async GetVaultSummary(vaultUuid: string) {
    return (await API.Get<VaultSummary200>(`/vaults/${vaultUuid}/summary`)).data;
  },
});
