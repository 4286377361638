import { ReactNode, useState } from "react";

import {
  Banner,
  Chip,
  formatCurrency,
  Icons,
  RadioButton,
  RadioGroup,
  RadioOrCheckboxSecondTier,
} from "@unchained/component-library";
import { useParams } from "react-router-dom";

import { Link } from "Components/Link";
import { ManifestStep } from "Routes/onboard/(shared)";
import { useWrapManifestMutation } from "Routes/onboard/(utils)";
import { useAddOrgOnboarding, useGetAccount } from "Shared/api";
import { useSKUPurchasable } from "Shared/api/v2/hooks/purchasables";
import { SELF_ONBOARDING_LINK } from "Shared/components/CTAs/DIYOnboardingCTA";
import { IraOrg } from "Specs/v1/getIraOrg/200";
import { CompleteOrg } from "Specs/v1/getOrg/200";

import { TrustUserOptionValue } from "../(trust)/payment";
import { getConciergePaymentOptions, mapOrgAndUsersToConciergeSKU } from "./paymentInfo";

export const ConciergeOnboarding = ({
  org,
  buttonText = "Continue",
  userCount,
  title = "Concierge onboarding",
  description = "Would you like help creating your vault?",
}: {
  org: CompleteOrg | IraOrg;
  buttonText?: string;
  userCount?: 1 | 2 | TrustUserOptionValue;
  title?: string;
  description?: ReactNode;
}) => {
  const { uuid } = useParams<{ uuid: string }>();
  const { user } = useGetAccount()?.data || {};
  const isIra = "iraPlan" in org;
  const wrapManifestMutation = useWrapManifestMutation({ orgUuid: uuid, isIra });
  const paymentOptions = getConciergePaymentOptions(org, userCount);

  const sku = mapOrgAndUsersToConciergeSKU(org, userCount as 1 | 2 | TrustUserOptionValue);
  const purchasable = useSKUPurchasable(sku);

  // signature doesn't apply to businesses
  const isSignature = user?.signature && (org as CompleteOrg)?.account_type !== "business";
  if (isSignature)
    description = (
      <div>
        All onboarding is a complementary with your{" "}
        <span className="font-semi text-gold-600">Signature subscription.</span>
      </div>
    );

  const [conciergeOnboarding, setConciergeOnboarding] = useState<boolean>(
    isIra ? org.conciergeOnboarding : org.concierge_onboarding
  );

  const addOrgOnboarding = wrapManifestMutation(useAddOrgOnboarding(uuid));
  const onContinueClick = () =>
    addOrgOnboarding.mutate({
      conciergeOnboarding: conciergeOnboarding,
      devicesRequested: conciergeOnboarding ? undefined : 0,
    });

  return (
    <ManifestStep
      actions={[
        {
          children: buttonText,
          onClick: onContinueClick,
          disabled: conciergeOnboarding === undefined,
        },
      ]}
      width="wide"
      title={title}
      description={description}
    >
      <RadioGroup
        className="flex w-full flex-col gap-3 sm:flex-row"
        name="conciergeOnboarding"
        onChange={e => setConciergeOnboarding(e.target.value as unknown as boolean)}
        value={conciergeOnboarding}
      >
        <RadioButton
          label="Concierge onboarding"
          value={true}
          description={
            <RadioOrCheckboxSecondTier
              upperLeft={
                isSignature ? (
                  <h2 className="flex flex-row items-center gap-2 text-d-sm font-semi text-gold-600">
                    <span>Included</span>
                    <Icons.Star />
                  </h2>
                ) : (
                  <h2 className="flex flex-row gap-2 text-d-sm font-semi text-gray-700">
                    {paymentOptions.concierge.priceDisplayPrefix || ""}
                    {formatCurrency(parseFloat(purchasable.usdPrice), "USD")}
                    {paymentOptions.concierge.note && (
                      <span className="mb-[2px] self-end text-md font-med text-gray-500">
                        {paymentOptions.concierge.note}
                      </span>
                    )}
                  </h2>
                )
              }
              upperRight={<Chip label="Recommended" type="success" />}
              lowerLeft={paymentOptions.concierge.copy}
            />
          }
        />
        <RadioButton
          label="Self setup"
          value={false}
          description={
            <RadioOrCheckboxSecondTier
              upperLeft={
                isSignature ? (
                  <h2 className="flex flex-row items-center gap-2 text-d-sm font-semi text-gold-600">
                    <span>Included</span>
                    <Icons.Star />
                  </h2>
                ) : (
                  <h2 className="flex flex-row gap-2 text-d-sm font-semi text-gray-700">
                    {paymentOptions.noConcierge.priceDisplayPrefix || ""}
                    {formatCurrency(0, "USD")}
                    {paymentOptions.noConcierge.note && (
                      <span className="mb-[2px] self-end text-md font-med text-gray-500">
                        {paymentOptions.noConcierge.note}
                      </span>
                    )}
                  </h2>
                )
              }
              lowerLeft={
                <div>
                  <div>{paymentOptions.noConcierge.copy}</div>
                  <Link
                    to={SELF_ONBOARDING_LINK}
                    target="_blank"
                    className="font-semi text-primary-600"
                    rel=""
                  >
                    Self-Service Onboarding Guide
                    <Icons.Link01 className="inline max-h-4 " />
                  </Link>
                </div>
              }
            />
          }
        />
      </RadioGroup>
      {conciergeOnboarding === false ? (
        <Banner type="warning" title="Are you sure? Please confirm that:">
          <ul>
            <li>
              I currently have two{" "}
              <Link to="https://help.unchained.com/what-hardware-wallets-do-you-support">
                supported hardware wallets
              </Link>{" "}
              in my possession that are initialized, backed up, and have updated firmware.
            </li>
            <li>
              I am familiar with the Unchained platform and am capable of{" "}
              <Link to="https://help.unchained.com/how-does-one-get-started-with-the-client-controlled-vault">
                building an Unchained vault
              </Link>{" "}
              without any assistance.
            </li>
            <li>
              I do not need a 1-hour onboarding session with Unchained's Concierge team to help me
              set up my hardware wallets or vault.
            </li>
          </ul>
        </Banner>
      ) : null}
    </ManifestStep>
  );
};
