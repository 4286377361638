import { useContext } from "react";

import { WizardStepperContext } from "@unchained/component-library";
import { useMutation } from "react-query";

import { useSwitchCurrentOrg } from "Components/Layout/Nav/Sidebar/OldSidebar/hooks";
import { useNavigate } from "Components/Link";
import { useLoadingContext } from "Contexts/LoadingContext";
import { OrgAPI } from "Shared/api";
import { CompleteOrgAccountTypeEnum, COMPLETE_ORG_ACCOUNT_TYPE } from "Specs/v1/getOrg/200";
import { useEasyToasts } from "Utils/toasts";

import { OnboardBasicContext } from "./context";

export function useOnboardNewOrg() {
  const { IRA, BUSINESS, INDIVIDUAL, TRUST } = COMPLETE_ORG_ACCOUNT_TYPE;
  const loading = useLoadingContext();
  const switchCurrentOrg = useSwitchCurrentOrg();
  const info = useContext(OnboardBasicContext);
  const createOrg = useMutation(
    ({ name, accountType }: { name: string; accountType: CompleteOrgAccountTypeEnum }) =>
      OrgAPI.Create({ name, accountType })
  );
  const { showErrorToast } = useEasyToasts();
  const navigate = useNavigate();
  const { person, personalOrg, from } = info;
  const { goToNext } = useContext(WizardStepperContext);

  // Org creation requires a name. For IRA and TRUST type orgs, we create one with a filler name
  // and the user can update it later.
  // (In the case of IRA, the backend handles naming entirely. For Trusts, we start blank).
  const createOrgWithName = (accountType: CompleteOrgAccountTypeEnum, name: string) => {
    if (loading.value) return;

    loading.set(true);
    createOrg.mutate(
      {
        name,
        accountType,
      },
      {
        onSuccess: async resp => {
          const uuid = resp.org?.uuid;
          await switchCurrentOrg(uuid);
          loading.set(false);
          navigate([`/onboard/${uuid}`, from].filter(Boolean).join("?from="));
        },
        onError: error => {
          loading.set(false);
          showErrorToast(error);
        },
      }
    );
  };

  return async (accountType: CompleteOrgAccountTypeEnum) => {
    if (accountType === IRA) {
      // be robust to commas in a name, but remove duplicate spaces
      const firstName = person.first_name.replace(/,/g, " ").replace(/ {2}/g, " ");
      const lastName = person.last_name.replace(/,/g, " ").replace(/ {2}/g, " ");
      createOrgWithName(IRA, `Solera National Bank FBO ${firstName} ${lastName}`);
    } else if (accountType === TRUST) {
      goToNext();
    } else if (accountType === BUSINESS) {
      goToNext();
    } else if (accountType === INDIVIDUAL) {
      await switchCurrentOrg(personalOrg.uuid);
      navigate([`/onboard/${personalOrg.uuid}`, from].filter(Boolean).join("?from="));
    }
  };
}
