import { ReactNode } from "react";

/* eslint-disable-next-line no-restricted-imports */
import { Cancel, CheckCircle } from "@mui/icons-material";

import { Link } from "Components/Link";

interface PaymentFinalizedProps {
  Icon: ReactNode;
  title: string;
  description: string;
  subdescription: ReactNode;
  className?: string;
}
const PaymentFinalized = ({
  Icon,
  title,
  description,
  subdescription,
  className,
}: PaymentFinalizedProps) => {
  return (
    <div className={`grid place-items-center border-2 border-gray-100 p-6 ${className}`}>
      {Icon}
      <h2 className="mt-3">{title}</h2>
      <div className="mb-3 mt-3 h-px w-full bg-gray-300"></div>
      <p className="text-gray-500">{description}</p>
      <br></br>
      <p className="text-gray-500">{subdescription}</p>
    </div>
  );
};

export const CompletedPayment = () => {
  return (
    <PaymentFinalized
      Icon={<CheckCircle fontSize="large" className=" text-green-500" />}
      title={"Purchase complete"}
      description="Thank you for your purchase. Your transaction has been successfully processed."
      subdescription={"You will receive an email shortly with a receipt of this purchase."}
    />
  );
};

export const SignatureZeroCostPaymentCompleted = () => {
  return (
    <PaymentFinalized
      Icon={<CheckCircle fontSize="large" className=" text-green-500" />}
      title={"No payment required"}
      description="Your annual account fees are included as a part of your Signature package."
      subdescription={""}
    />
  );
};

export const FailedPayment = () => {
  return (
    <PaymentFinalized
      className="mb-14 md:mb-0"
      Icon={<Cancel fontSize="large" className="text-red-600" />}
      title={"Purchase unsuccessful"}
      description="We were unable to complete this transaction. Please go back and re-submit, or try again with a different payment card."
      subdescription={
        <span>
          If you need further help, please contact{" "}
          <Link to="mailto:support@unchained.com">support@unchained.com</Link>
        </span>
      }
    />
  );
};
