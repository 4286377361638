import { Button } from "@unchained/component-library";

import { Link } from "Components/Link";
import { getConfig } from "Utils/config";

import { SellHeader } from "../components/SellHeader";

type NetworkErrorStepProps = {
  onClose: () => void;
};
export const NetworkErrorStep = ({ onClose }: NetworkErrorStepProps) => {
  const contactEmail = getConfig("email.help");
  const SubTitle = () => (
    <p className="text-md">
      We apologize for any inconvenience. Questions? Email
      <Link to={`mailto:${contactEmail}`}> {contactEmail} </Link>
    </p>
  );
  return (
    <div className="w-10/12 max-w-md">
      <SellHeader title="Network error" subTitle={<SubTitle />} />
      <Button onClick={onClose} fullWidth={true} type={"secondary"}>
        Close
      </Button>
    </div>
  );
};
