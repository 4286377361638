import { AnyAction } from "redux";

import {
  ADD_PENDING_MEMBERS_TO_ACCOUNT_CURRENT_ORG,
  ADD_USER_TO_ACCOUNT_CURRENT_ORG_ROLE,
  REMOVE_ACCEPTED_MEMBER_FROM_ACCOUNT_CURRENT_ORG,
  REMOVE_PENDING_MEMBER_FROM_ACCOUNT_CURRENT_ORG,
  REMOVE_USER_FROM_ACCOUNT_CURRENT_ORG_ROLE,
  REPLACE_ACCOUNT_CURRENT_ORG,
  UPDATE_ACCOUNT_CURRENT_ORG_STATUS,
  UPDATE_ACCOUNT_ORGS,
} from "Actions/accountActions/orgActions";
import { REQUEST_STATUS } from "Shared/api/api";
import { OrgMemberAllowedActionsEnum, OrgMemberStateEnum } from "Specs/v1/getAccount/200";
import { CompleteOrg } from "Specs/v1/getOrg/200";
import { Role } from "Specs/v1/getTransactionRequests/200";

const personalOrgInitialState = {
  uuid: "",
  name: "",
  personal: true,
  account_type: "individual",
  type: "client",
  denied_reason: "",
  state: "pending_basic_info",
  advanced_profile_state: "pending_submission",
  allowed_actions: [],
  multi_institution_vaults: false,

  accepted_members: [],
  pending_members_emails: [],
  max_members: 1,
  available_memberships: 0,

  child_roles: [],
  child_org_groups: [],
  user_groups: [],
  account_key_groups: [],
  vault_groups: [],
  loan_groups: [],
  active_vaults_count: 0,
  active_loans_count: 0,

  bank_accounts: [],

  partnership_token: "",
  entity: {},
} as CompleteOrg;

// Just exported for testing
export const initialState = {
  personal: {
    ...personalOrgInitialState,
  },
  memberships: [] as {
    org: CompleteOrg;
    allowed_actions: OrgMemberAllowedActionsEnum;
    state: OrgMemberStateEnum;
    roles: Role[];
  }[],
  current: {
    ...personalOrgInitialState,
  },
  status: REQUEST_STATUS.PENDING,
};
type OrgsState = typeof initialState;

const addUserToCurrentOrgRole = (currentOrg, user, role) => {
  const child_roles = currentOrg.child_roles.map(childRole => {
    if (childRole.uuid === role.uuid) {
      return {
        ...childRole,
        users: [...childRole.users, user],
      };
    } else {
      return childRole;
    }
  });
  return {
    ...currentOrg,
    child_roles,
  };
};

const removeUserFromCurrentOrgRole = (currentOrg, user, role) => {
  const child_roles = currentOrg.child_roles.map(childRole => {
    if (childRole.uuid === role.uuid) {
      return {
        ...childRole,
        users: childRole.users.filter(childRoleUser => {
          return childRoleUser.username !== user.username;
        }),
      };
    } else {
      return childRole;
    }
  });
  return {
    ...currentOrg,
    child_roles,
  };
};

const removeAcceptedMemberFromCurrentOrg = (currentOrg, user) => {
  const accepted_members = currentOrg.accepted_members.filter(member => {
    return member.user.username !== user.username;
  });

  const child_roles = currentOrg.child_roles
    .filter(childRole => {
      return !childRole.dedicated || childRole.name !== user.username;
    })
    .map(childRole => {
      return {
        ...childRole,
        users: childRole.users.filter(childRoleUser => {
          return childRoleUser.username !== user.username;
        }),
      };
    });

  const user_groups = currentOrg.user_groups.filter(group => {
    return !group.dedicated || group.name !== user.username;
  });

  const available_memberships = currentOrg.available_memberships + 1;

  return {
    ...currentOrg,
    accepted_members,
    child_roles,
    user_groups,
    available_memberships,
  };
};

export const orgsReducer = (
  state: OrgsState = initialState,
  action: AnyAction = {} as unknown as AnyAction
): OrgsState => {
  const { type, payload } = action;

  switch (type) {
    // OrgForList menu & lens

    case UPDATE_ACCOUNT_ORGS: {
      return {
        ...state,
        personal: action.personal,
        memberships: action.memberships,
      };
    }

    case REPLACE_ACCOUNT_CURRENT_ORG: {
      return {
        ...state,
        current: {
          ...personalOrgInitialState,
          ...payload,
        },
      };
    }

    case UPDATE_ACCOUNT_CURRENT_ORG_STATUS: {
      return {
        ...state,
        status: payload,
      };
    }

    // Managing current org's members

    case ADD_PENDING_MEMBERS_TO_ACCOUNT_CURRENT_ORG: {
      return {
        ...state,
        current: {
          ...state.current,
          available_memberships: state.current.available_memberships - 1,
          pending_members_emails: [...state.current.pending_members_emails, ...payload],
        },
      };
    }

    case REMOVE_PENDING_MEMBER_FROM_ACCOUNT_CURRENT_ORG: {
      return {
        ...state,
        current: {
          ...state.current,
          pending_members_emails: state.current.pending_members_emails.filter(
            pending_member_email => pending_member_email !== payload
          ),
          available_memberships: state.current.available_memberships + 1,
        },
      };
    }

    case REMOVE_ACCEPTED_MEMBER_FROM_ACCOUNT_CURRENT_ORG: {
      return {
        ...state,
        current: removeAcceptedMemberFromCurrentOrg(state.current, payload),
      };
    }

    // Managing current org's user roles

    case ADD_USER_TO_ACCOUNT_CURRENT_ORG_ROLE: {
      return {
        ...state,
        current: addUserToCurrentOrgRole(state.current, action.user, action.role),
      };
    }

    case REMOVE_USER_FROM_ACCOUNT_CURRENT_ORG_ROLE: {
      return {
        ...state,
        current: removeUserFromCurrentOrgRole(state.current, action.user, action.role),
      };
    }

    default:
      return state;
  }
};
