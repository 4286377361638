import { ReactNode } from "react";

/* eslint-disable-next-line no-restricted-imports */
import { HelpOutline } from "@mui/icons-material";
import { Tooltip } from "@unchained/component-library";
import cx from "classnames";

import { BTC, USD } from "Contexts/BuyBitcoin/buyBitcoinConstants";
import { Currency } from "Contexts/BuyBitcoin/types";
import { fiatWithUnit } from "Utils/currency";

import styles from "./AmountDisplay.module.scss";

interface AmountDisplayProps {
  amount?: string;
  label: string;
  labelAddOn?: ReactNode;
  amountType?: "normal" | "error";
  children?: ReactNode | ReactNode[];
  currency?: Currency;
}

export const AmountDisplay = ({
  amount,
  label,
  labelAddOn,
  amountType = "normal",
  children,
  currency = USD,
}: AmountDisplayProps) => {
  const emptyAmount = currency === USD ? "$---" : "---";
  const formattedAmount = amount
    ? currency === USD
      ? fiatWithUnit(parseFloat(amount))
      : `${amount} BTC`
    : emptyAmount;
  return (
    <div className="flex flex-row items-center">
      <span className={styles.label}>{label}:&nbsp;</span>
      <span
        className={cx(styles.amount, {
          [styles.amount_error]: amountType === "error",
          [styles.amount_btc]: currency === BTC,
        })}
      >
        {formattedAmount}
      </span>
      {labelAddOn ? labelAddOn : null}
      {children}
    </div>
  );
};

interface AmountDisplayWithTooltipProps extends Omit<AmountDisplayProps, "children"> {
  tooltipContent: ReactNode;
  label: string;
}

export const AmountDisplayWithTooltip = ({
  tooltipContent,
  label,
  ...other
}: AmountDisplayWithTooltipProps) => {
  return (
    <AmountDisplay label={label} {...other}>
      <Tooltip
        data-testid={`amountDisplayToolTip-${label}`}
        content={tooltipContent}
        arrow
        placement="top"
        compact
      >
        <HelpOutline classes={{ root: styles.infoIcon }} />
      </Tooltip>
    </AmountDisplay>
  );
};

interface CurrentPriceDisplayProps {
  price: string;
  label?: string;
}

export const CurrentPriceDisplay = ({
  price,
  label = "Current price",
  ...other
}: CurrentPriceDisplayProps) => {
  return <AmountDisplay {...other} amount={price} label={label} />;
};
