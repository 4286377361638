import { HtmlHTMLAttributes, ReactNode } from "react";

/* eslint-disable-next-line no-restricted-imports */
import { ExpandLess } from "@mui/icons-material";
import { Tooltip } from "@unchained/component-library";
import cn from "classnames";

import { Link } from "Components/Link";
import { CompleteOrg } from "Specs/v1/getAccount/200";

import { orgDisplayInfo } from "./accountHelpers";

const MAX_TITLE_LENGTH = 22;

/**
 * A row designed to contain an avatar and other content.
 * Can take onClick and/or to props to make it a link or button.
 * */
export const AvatarRow = ({
  to,
  className,
  style,
  children,
  onClick,
  ...other
}: {
  to?: string;
  className?: string;
  style?: React.CSSProperties;
  children: ReactNode;
  onClick?: () => void;
} & HtmlHTMLAttributes<HTMLAnchorElement | HTMLButtonElement>) => {
  const baseClass = "flex w-full flex-row items-center justify-between transition-all group";
  const props = { className: cn(baseClass, className), style, onClick, to, ...other };
  return to ? <Link {...props}>{children}</Link> : <button {...props}>{children}</button>;
};

/** Typical left-side content for an AvatarRow. Formats Avatar, title, and subtitle consistently. */
export const AvatarRowLeft = ({
  avatar,
  title,
  subtitle,
  classes = {},
}: {
  avatar?: ReactNode;
  title?: string;
  subtitle?: string;
  classes?: {
    title?: string;
    subtitle?: string;
  };
}) => {
  const truncate = title?.length > MAX_TITLE_LENGTH;
  const truncatedTitle = truncate ? title.slice(0, MAX_TITLE_LENGTH - 3).concat("...") : title;

  const titleNode = title ? (
    <p className={cn("break-words text-left text-sm font-semi", classes.title)}>{truncatedTitle}</p>
  ) : null;

  return (
    <div className="flex flex-row items-center gap-4 py-2 pl-2">
      {avatar}
      <div className="flex flex-col items-start justify-self-start py-[1px]">
        {truncate ? <Tooltip content={title}>{titleNode}</Tooltip> : titleNode}
        {subtitle ? (
          <p className={cn("break-words text-left text-sm font-reg", classes.subtitle)}>
            {subtitle}
          </p>
        ) : null}
      </div>
    </div>
  );
};

export const OrgAvatarRowLeft = ({
  org,
  isUnchainedAdmin,
  subtitle,
  classes = {},
}: {
  org: CompleteOrg;
  isUnchainedAdmin: boolean;
  subtitle?: string;
  classes?: {
    title?: string;
    subtitle?: string;
  };
}) => {
  const {
    title,
    subtitle: calculatedSubtitle,
    Avatar: OrgAvatar,
  } = orgDisplayInfo(org, isUnchainedAdmin);

  return (
    <AvatarRowLeft
      avatar={<OrgAvatar title={title} />}
      title={title}
      subtitle={subtitle || calculatedSubtitle}
      classes={classes}
    />
  );
};

/** An AvatarRow for the current org, with an expand icon. Used to open and close the account switcher. */
export const CurrentOrgAvatarItem = ({
  isOpen,
  setOpen,
  org,
  isUnchainedAdmin,
  direction = "down",
  className,
}: {
  org: CompleteOrg;
  isOpen: boolean;
  setOpen: (boolean) => void;
  isUnchainedAdmin: boolean;
  direction?: "up" | "down";
  className?: string;
}) => {
  let expandDirection;
  if (isOpen) {
    expandDirection = direction === "up" ? "rotate-180" : "rotate-0";
  } else {
    expandDirection = direction === "up" ? "rotate-0" : "rotate-180";
  }

  return (
    <AvatarRow
      data-testid="account-switcher"
      className={cn("rounded-lg", "relative z-10 bg-[#F5F7FA] hover:bg-gray-100", className)}
      style={{ height: 58 }}
      onClick={() => setOpen(!isOpen)}
    >
      <OrgAvatarRowLeft org={org} isUnchainedAdmin={isUnchainedAdmin} />
      <div className="flex h-full items-center border-l border-l-gray-200 px-3">
        <ExpandLess htmlColor="black" className={cn("flex transition-all", expandDirection)} />
      </div>
    </AvatarRow>
  );
};
