import React from "react";

/* eslint-disable-next-line no-restricted-imports */
import { HelpOutline } from "@mui/icons-material";
import { Tooltip } from "@unchained/component-library";
import cx from "classnames";

import { Fee } from "Contexts/BuyBitcoin/types";
import { formatCurrency } from "Utils/strings";

import styles from "./TradingFeesTooltip.module.scss";

interface TradingFeesTooltipProps {
  infoIconClass?: string;
  fees: Fee[];
}
/**
 * Component which displays an icon containing a tooltip that describes the bracketed fee rates.
 * @param {string} infoIconClass - className to add additional styles to the icon.
 * @returns {React.Component}
 */
export const TradingFeesTooltip = ({ infoIconClass, fees }: TradingFeesTooltipProps) => {
  const feeRatesTexts = fees?.map(fee => {
    return (
      <div key={fee.minPurchaseAmountUsd}>{`$${formatCurrency(fee.minPurchaseAmountUsd)} and up - ${
        fee.feeRatePct
      }%`}</div>
    );
  });

  return (
    <Tooltip
      arrow
      placement="top"
      compact
      content={
        <>
          <div className={styles.title}>Trading fee rates</div>
          {feeRatesTexts}
        </>
      }
      data-testid="tradingFeesToolTip"
    >
      <HelpOutline className={cx(infoIconClass, styles.infoIcon)} />
    </Tooltip>
  );
};
