import React from "react";

/* eslint-disable-next-line no-restricted-imports */
import {
  Delete as RemoveIcon,
  GetApp as DownloadIcon,
  Search as PreviewIcon,
} from "@mui/icons-material";
import { Grid, IconButton, Tooltip } from "@mui/material";
import cn from "classnames";
import PropTypes from "prop-types";

import { AppModalManager } from "Shared/components/Modals";
import { humanSize } from "Utils/strings";

import { FilePreviewModal } from "./FilePreviewModal";
import styles from "./FileUpload.module.scss";

class FilePreview extends React.Component {
  static propTypes = {
    previewMessage: PropTypes.string.isRequired,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    thumbnail_data: PropTypes.string,
    size: PropTypes.number.isRequired,
    onDownload: PropTypes.func,
    remove: PropTypes.func,
    className: PropTypes.string,
  };

  render() {
    const { name, size, thumbnail_data, remove, onDownload, className } = this.props;

    return (
      <Grid container>
        <Grid
          container
          item
          xs={8}
          className={cn(styles.preview, className)}
          alignContent="center"
          justifyContent="center"
        >
          <Grid item className={styles.name}>
            <p>{name}</p>
          </Grid>
          <Grid item className={styles.size}>
            <p>{humanSize(size)}</p>
          </Grid>
        </Grid>

        <Grid container item xs={1} direction="column" justifyContent="space-evenly">
          {thumbnail_data && (
            <Grid item xs={1}>
              <Tooltip title="Preview">
                <IconButton size="small" onClick={this.openPreviewModal}>
                  <PreviewIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Grid>
          )}

          {onDownload && (
            <Grid item xs={1}>
              <Tooltip title="Download">
                <IconButton size="small" onClick={onDownload}>
                  <DownloadIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Grid>
          )}

          {remove && (
            <Grid item xs={1}>
              <Tooltip title="Remove">
                <IconButton size="small" onClick={remove}>
                  <RemoveIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }

  openPreviewModal = () => {
    const { previewMessage, name, label, thumbnail_data } = this.props;
    AppModalManager.open(() => {
      return (
        <FilePreviewModal
          previewMessage={previewMessage}
          name={name}
          label={label}
          thumbnail_data={thumbnail_data}
        />
      );
    });
  };
}

export { FilePreview };
