import React, { Component } from "react";

import { CircularProgress, Grid, Table, TableBody, TableRow } from "@mui/material";
import { Card, CardContent, CardHeader, Key, KeyGrid } from "@unchained/component-library";
import get from "lodash/get";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { continuousGetOperationUntilStatusUpdateAction } from "Actions/transactionActions/spendingActions";
import { useNavigate } from "Components/Link";
import { PrimaryButton } from "Components/Shared/Elements/Buttons/PrimaryButton";
import { mapKeyRoleToKeyOwner } from "Components/Shared/Elements/Keys/utils";
import { Loading } from "Components/Shared/Elements/Loading";
import { SummaryTableCell } from "Components/Shared/Elements/Summary/SummaryTableCell";
import {
  spendingOperationSelector,
  spendingSigRequestsSelector,
  spendingTransactionSelector,
} from "Redux/selectors/spendingSelectors";
import { TransactionAPI } from "Shared/api/transactionApi";
import { hasPermission } from "Utils/acls";
import { btcOperationTypes } from "Utils/enums";
import { capitalize } from "Utils/strings";

import { TransactionDetails } from "../TransactionDetails";
import styles from "./BroadcastStep.module.scss";
import { SpendConfirmation } from "./SpendConfirmation";

class BroadcastStepBase extends Component {
  static propTypes = {
    allowedActions: PropTypes.arrayOf(PropTypes.string).isRequired,
    operationUUID: PropTypes.string.isRequired,
    sigRequests: PropTypes.arrayOf(PropTypes.object),
    txRequestState: PropTypes.string.isRequired,
    continuousGetOperationUntilStatusUpdate: PropTypes.func.isRequired,
    accountType: PropTypes.string,
    accountUUID: PropTypes.string.isRequired,
    txRequestUUID: PropTypes.string.isRequired,
  };

  state = {
    errorMessage: "",
    broadcastingState: "broadcast",
  };

  getTitle = () => {
    if (this.props.txRequestState === "broadcasted") {
      return "Pending confirmation";
    }
    return "Broadcast";
  };

  broadcastAllowed = () =>
    hasPermission(this.props.allowedActions, "execute") &&
    this.props.operationType !== btcOperationTypes.vault_sale_transaction;

  getKey = sigRequest => {
    const ownerType = mapKeyRoleToKeyOwner({ role: sigRequest.account_key.role });

    return (
      <Key
        size="sm"
        label={sigRequest.account_key.name}
        ownerType={ownerType}
        status={
          !!sigRequest.signed_psbt && {
            icon: "signed",
            label: "Signed",
          }
        }
      />
    );
  };

  getSignedKeys = () => {
    const { sigRequests } = this.props;
    return (
      <KeyGrid size="sm">
        {sigRequests[0].signed_psbt && <Grid item>{this.getKey(sigRequests[0])}</Grid>}
        {sigRequests[1].signed_psbt && <Grid item>{this.getKey(sigRequests[1])}</Grid>}
        {sigRequests[2].signed_psbt && <Grid item>{this.getKey(sigRequests[2])}</Grid>}
      </KeyGrid>
    );
  };

  getContent = () => {
    const { txRequestState } = this.props;
    const { errorMessage, broadcastingState } = this.state;
    switch (txRequestState) {
      case "fully_signed": {
        if (this.broadcastAllowed()) {
          return (
            <Grid item xs={10}>
              <Card>
                <CardHeader title="Ready to broadcast" className="text-center" />
                <CardContent>
                  <Grid container spacing={2} direction="column" alignItems="center">
                    {broadcastingState !== "broadcasting" && (
                      <>
                        <Grid item xs={12}>
                          <p className={styles.broadcastText}>
                            Your transaction is ready for broadcast
                          </p>
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12}>
                      <p className={styles.error}>{errorMessage}</p>
                    </Grid>

                    <Grid item xs={12}>
                      <PrimaryButton
                        text={capitalize(broadcastingState)}
                        disabled={broadcastingState === "broadcasting"}
                        onClick={() => this.handleBroadcastClick()}
                        icon={broadcastingState === "broadcasting" ? CircularProgress : null}
                        iconclasses={styles.loadingButton}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          );
        }
        return <div>Waiting for Broadcast</div>;
      }

      case "broadcasted": {
        return <SpendConfirmation />;
      }

      case "confirmed": {
        return <div>Confirmed</div>;
      }

      default: {
        return <Loading />;
      }
    }
  };

  handleBroadcastClick = async () => {
    this.setState({ broadcastingState: "broadcasting" }, this.broadcastTransaction);
  };

  broadcastTransaction = async () => {
    const {
      accountUUID,
      continuousGetOperationUntilStatusUpdate,
      txRequestUUID,
      operationType,
      operationUUID,
      navigate,
      accountType,
    } = this.props;

    try {
      // broadcast transaction
      await TransactionAPI.BroadcastTransactionRequest(txRequestUUID);
      // if it's successful, then continuously call for the vault/loan spend data until that returns broadcasted
      const data = await continuousGetOperationUntilStatusUpdate(
        operationType,
        accountUUID,
        operationUUID,
        "pending",
        2,
        navigate
      );
      const newState = {
        broadcastingState: "broadcast",
      };
      if (!data) {
        newState.errorMessage = `Your broadcast is being processed and will be sent to Bitcoin network shortly. Status can be tracked on the ${accountType} page.`;
      }
      this.setState(newState);
    } catch (e) {
      let errorMessage;
      if (e.response && e.response.status === 409) {
        errorMessage =
          "There was a problem retrieving your transaction's broadcast info. Please reload the page";
      } else {
        errorMessage = get(
          e,
          "response.data.message",
          "There was an error broadcasting your transaction."
        );
      }

      console.log(e);
      this.setState({ errorMessage: errorMessage, broadcastingState: "broadcast" });
    }
  };

  render() {
    return (
      <Grid container spacing={2} alignItems="flex-start" justifyContent="center">
        <Grid item xs={12} md={6} classes={{ container: styles.leftContainer }}>
          {this.getContent()}
        </Grid>

        <Grid item xs={12} md={6} container>
          <TransactionDetails showApprovals={true} />
          <Grid item container>
            <Grid item xs={12} classes={{ item: styles.signedKeyRow }}>
              <Table>
                <TableBody>
                  <TableRow>
                    <SummaryTableCell component="th">Signatures</SummaryTableCell>
                    <SummaryTableCell className={styles.signedKeyList}>
                      {this.getSignedKeys()}
                    </SummaryTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...spendingOperationSelector(state),
    ...spendingTransactionSelector(state),
    ...spendingSigRequestsSelector(state),
  };
};

const mapDispatchToProps = {
  continuousGetOperationUntilStatusUpdate: continuousGetOperationUntilStatusUpdateAction,
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(BroadcastStepBase);

export const BroadcastStep = props => {
  const navigate = useNavigate();
  return <Connected {...props} navigate={navigate} />;
};
