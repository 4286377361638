import { Divider } from "@mui/material";
import { Icons, Tooltip } from "@unchained/component-library";

import { Link } from "Components/Link";
import { WireInstructionItem } from "Components/TradingDesk/components/WireInstructionItem";
import { getConfig } from "Utils/config";
import { formatCurrency } from "Utils/strings";

import { AvailableCashTooltip } from "./components/AvailableCashTooltip";

type WireInstructionsProps = {
  orgUuid: string;
  cashBalance?: number;
  availableBalance?: number;
  minimumWireAmount?: number;
};
export const WireInstructions = ({
  orgUuid,
  cashBalance,
  availableBalance,
  minimumWireAmount,
}: WireInstructionsProps) => {
  const isCashBalanceNegative = cashBalance && Math.sign(cashBalance) === -1;
  const cashBalanceDisplay =
    cashBalance !== undefined && availableBalance !== undefined
      ? `${isCashBalanceNegative ? "-" : ""}$${formatCurrency(Math.abs(cashBalance))}`
      : "---";

  const cashBalanceColor = isCashBalanceNegative ? "text-red-700" : "text-gray-600";

  const availableBalanceDisplay =
    availableBalance !== undefined ? `$${formatCurrency(availableBalance)} available` : "---";

  const tradingBankAccount = getConfig("trading_bank_account");

  const bankAccountName = tradingBankAccount.account_holder.name;
  const bankAccountAddressOne = tradingBankAccount.account_holder.address_line_1;
  const bankAccountAddressTwo = tradingBankAccount.account_holder.address_line_2;
  const bankAccountAddressThree = tradingBankAccount.account_holder.address_line_3;
  const routingNumber = tradingBankAccount.routing_number;
  const accountNumber = tradingBankAccount.account_number;

  const bankName = tradingBankAccount.name;

  const bankAddressOne = tradingBankAccount.address_line_1;
  const bankAddressTwo = tradingBankAccount.address_line_2;
  const bankAddressThree = tradingBankAccount.address_line_3;
  return (
    <div className="rounded border-[1px] border-gray-200 bg-gray-50 py-4">
      <div className="px-4">
        <p
          style={{ fontFamily: "Inter" }}
          className="mb-4 text-sm font-med uppercase text-gray-800"
        >
          Cash Balance
        </p>
        <p
          data-testid="cashBalance"
          className={`mb-2 text-[1.875rem] font-semi ${cashBalanceColor}`}
        >
          {cashBalanceDisplay}
        </p>
        <div className="flex flex-row">
          <p data-testid="availableBalance" className="text-sm font-reg text-gray-600">
            {availableBalanceDisplay}
          </p>

          <AvailableCashTooltip placement="top" />
        </div>
      </div>

      <Divider className="mb-4 mt-4" />
      <div className="px-4">
        <p style={{ fontFamily: "Inter" }} className="text-sm font-med uppercase text-gray-800">
          Wire instructions
        </p>

        {!!minimumWireAmount && (
          <WireInstructionItem
            instructionStyles={{ container: "mt-2.5", content: "text-red-600 font-med" }}
            title={
              <div className="flex flex-row">
                Minimum wire amount
                <Tooltip
                  placement="top-start"
                  content="This is the minimum amount required to settle an in progress trade. You may deposit more. "
                >
                  <div>
                    <Icons.HelpCircle className="relative top-0.5 ml-1 h-4 text-gray-400" />
                  </div>
                </Tooltip>
              </div>
            }
            content={`$${formatCurrency(minimumWireAmount)}`}
          />
        )}
        <WireInstructionItem
          instructionStyles={{ container: "mt-2.5" }}
          title="Account name and address"
          content={[
            bankAccountName,
            <br key={"breakAddress"} />,
            `${bankAccountAddressOne}, ${bankAccountAddressTwo}`,
            <br key={"streetBreak"} />,
            bankAccountAddressThree,
          ]}
        />

        <WireInstructionItem
          instructionStyles={{ container: "mt-2.5" }}
          title="Bank name and address"
          content={[
            bankName,
            <br key={"breakAccountName"} />,
            `${bankAddressOne} ${bankAddressTwo}`,
            <br key={"breakAccountAddress"} />,
            bankAddressThree,
          ]}
        />
        <WireInstructionItem
          instructionStyles={{ container: "mt-2.5" }}
          title="Routing number"
          content={`${routingNumber}`}
        />
        <WireInstructionItem
          instructionStyles={{ container: "mt-2.5" }}
          title="Account number"
          content={`${accountNumber}`}
        />

        <WireInstructionItem
          instructionStyles={{ container: "mt-2.5" }}
          title={"Unchained ID (for memo field)"}
          content={orgUuid}
          textToBeCopied={orgUuid}
        />
      </div>
    </div>
  );
};

export const WireNotes = () => {
  return (
    <div className="mb-2 rounded border-[1px] border-gray-200 bg-gray-50 p-4 py-4">
      <h3 className="font-inter text-sm font-med uppercase text-gray-800">important</h3>
      <ul className="ml-0 mt-2 list-disc px-2">
        <li className="ml-2">
          You must note the <span className="font-semi text-gray-600">Unchained ID</span> in the
          memo field of your wire.
        </li>
        <li className="ml-2 mt-2">
          Ensure the wire is sent from the{" "}
          <span>
            <Link to={`/payment-methods?tab=bankAccounts`} target="_blank">
              bank account on file
            </Link>
            .
          </span>
        </li>

        <li className="ml-2 mt-2">
          All linked bank accounts must match your legal name on your Unchained account.
        </li>
        <li className="ml-2 mt-2">
          Wires sent after 3:00 pm (CT) on a business day will be available within two business
          days. Business days are Monday through Friday and do not include U.S. holidays.
        </li>
      </ul>
    </div>
  );
};
