/* eslint-disable-next-line no-restricted-imports */
import { HelpOutline } from "@mui/icons-material";
import { Tooltip } from "@unchained/component-library";
import { useFormikContext } from "formik";

import { BTC, USD } from "Contexts/BuyBitcoin";
import { TradeAmount } from "Contexts/BuyBitcoin/types";
import { formatCurrency } from "Utils/strings";

import { useAmountOfInverseCurrency } from "../../hooks";
import { BuyBitcoinValues } from "./BuyBitcoinForm";
import { TransactionAmountSubtext } from "./TransactionAmountSubtext";

type InverseCurrencyAmountProps = {
  maximumTransactionAmount: TradeAmount;
  minimumTransactionAmount: TradeAmount;
  bitcoinPrice: string;
  usdTooltipContent?: string;
};
const InverseCurrencyAmount = ({
  maximumTransactionAmount,
  minimumTransactionAmount,
  bitcoinPrice,
  usdTooltipContent,
}: InverseCurrencyAmountProps) => {
  const {
    values: { amount, currency, max, min },
    errors,
  } = useFormikContext<BuyBitcoinValues>();

  const inverseOfCurrentAmount = useAmountOfInverseCurrency(currency, amount, bitcoinPrice);
  const label = currency === USD ? BTC : USD;
  const isValidPurchaseAmount = !errors.amount;

  const createSuffix = () => {
    if (currency === BTC && usdTooltipContent) {
      return (
        <Tooltip arrow placement="top" compact content={usdTooltipContent}>
          <HelpOutline className="relative top-[-2px] ml-1  text-lg text-gray-400" />
        </Tooltip>
      );
    }
  };

  const createDisplayAmount = () => {
    if (isValidPurchaseAmount) {
      let patchedAmount = inverseOfCurrentAmount;
      if (max) {
        patchedAmount = maximumTransactionAmount[label];
      } else if (min) {
        patchedAmount = minimumTransactionAmount[label];
      }
      const displayAmount =
        currency === USD ? `~${patchedAmount}` : `~$${formatCurrency(patchedAmount)}`;
      return displayAmount;
    } else {
      return "---";
    }
  };

  return (
    <TransactionAmountSubtext
      isValidAmount={isValidPurchaseAmount}
      label={label}
      text={createDisplayAmount()}
      suffixChild={createSuffix()}
    />
  );
};

export { InverseCurrencyAmount };
