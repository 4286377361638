/* eslint-disable-next-line no-restricted-imports */
import { Close } from "@mui/icons-material";
import cn from "classnames";

import { useNavigationDispatch, useNavigationState } from "Contexts/Navigation";

export const MobileUnderlay = () => {
  const { drawerOpen } = useNavigationState();
  const { setDrawerOpen } = useNavigationDispatch();

  return (
    <div
      className={cn(
        "fixed inset-0 z-40 h-screen w-full bg-gray-500 bg-opacity-0 transition-all duration-700",
        {
          invisible: !drawerOpen,
          "bg-opacity-90": drawerOpen,
        }
      )}
      onClick={() => setDrawerOpen(false)}
    >
      <div className="flex justify-end p-3">
        <button
          className={cn(
            "flex items-center justify-center rounded-md p-2 transition-colors hover:bg-gray-300",
            { invisible: !drawerOpen }
          )}
          onClick={() => setDrawerOpen(false)}
        >
          <Close htmlColor="#fff" />
        </button>
      </div>
    </div>
  );
};
