import React from "react";

import { KeyGrid } from "@unchained/component-library";

import { keyAvatarProps, mapKeyRoleToKeyOwner } from "Components/Shared/Elements/Keys/utils";
import { AccountKey } from "Components/VaultsView/types";

import { SigningKey } from "./ConfirmSigningKeys";
import "./SigningKeySelection.scss";

interface SigningKeySelectionProps {
  orgUUID?: string;
  accountKeys: AccountKey[];
  key1: SigningKey;
  key2: SigningKey;
  key3: SigningKey;
  handleKeyClick: (string, bool) => void;
}

export const SigningKeySelection = ({
  orgUUID,
  accountKeys,
  key1,
  key2,
  key3,
  handleKeyClick,
}: SigningKeySelectionProps) => {
  const isSelected = (key: AccountKey) => {
    if (key1.uuid === key.uuid) {
      return key1.active;
    }
    if (key2.uuid === key.uuid) {
      return key2.active;
    }
    if (key3.uuid === key.uuid) {
      return key3.active;
    }
    return false;
  };

  const sortedAccountKeys = [
    accountKeys.find(key => key.uuid === key1.uuid),
    accountKeys.find(key => key.uuid === key2.uuid),
    accountKeys.find(key => key.uuid === key3.uuid),
  ];

  return (
    <KeyGrid
      size="sm"
      keys={sortedAccountKeys.map((key, index) => {
        const isSharedWithMe = orgUUID && "owner" in key && key.owner?.uuid !== orgUUID;
        const ownerType = mapKeyRoleToKeyOwner({ role: key.role, isSharedWithMe });
        return {
          label: key.name,
          isInteractive: true,
          displayAvatar: key.role !== "client",
          ...keyAvatarProps(key),
          onClick: () => handleKeyClick(index, isSelected(key)),
          ownerType,
          selected: isSelected(key),
          role: "button",
        };
      })}
    />
  );
};
