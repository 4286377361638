/* eslint-disable-next-line no-restricted-imports */
import { withAccount } from "Shared/api";

import { NewSidebar } from "./NewSidebar";
import { OldSidebar } from "./OldSidebar";

type Props = { mobile?: boolean; className?: string; id?: string };
export const Sidebar = withAccount<Props>(
  ({ mobile = false, className = "", id, user }) =>
    user.all_features.unified_ia ? (
      <NewSidebar mobile={mobile} className={className} id={id} />
    ) : (
      <OldSidebar mobile={mobile} className={className} id={id} />
    ),
  { loader: null }
);
