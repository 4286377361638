import { Banner } from "@unchained/component-library";

import { WithdrawalRAStepperWizard } from "Components/IRAs/WithdrawalIra/WithdrawalIraStepper";
import { Link } from "Components/Link";
import { AppModalManager } from "Shared/components/Modals";

type InitialBannerWarningProps = { onStepperOpen?: () => void };
export const InitialBannerWarning = ({ onStepperOpen }: InitialBannerWarningProps) => {
  return (
    <Banner type="error" title="Paperwork required before withdrawal" className="mt-1">
      Before proceeding with an IRA withdrawal, please complete the{" "}
      <Link
        className=" text-primary-400 underline"
        onClick={() => {
          if (onStepperOpen) {
            onStepperOpen();
          }
          AppModalManager.open(() => <WithdrawalRAStepperWizard />);
        }}
      >
        corresponding paperwork
      </Link>
      . Failure to do so is a breach of our Terms of Service and could result in negative tax
      consequences.
      <br />
      <br />
      Note: for outbound in-kind transfers please contact the receiving custodian directly to
      initiate the request.
    </Banner>
  );
};
