import React, { useContext } from "react";

import { WizardStepperContext } from "@unchained/component-library";

import { IconPage } from "Routes/onboard/(shared)/IconPage";

export const PaymentConfirmation = ({
  subtitle = "You can now proceed to Unchained",
  onNext,
  buttonText = "Continue",
  isSignature = false,
}: {
  subtitle?: string;
  onNext?: () => void;
  buttonText?: string;
  isSignature?: boolean;
}) => {
  const { goToNext } = useContext(WizardStepperContext);
  const next = onNext || goToNext;

  if (isSignature) {
    return (
      <IconPage
        title="Your payment has been processed."
        subtitle="Your annual account fees are included as a part of your Signature package. Continue to finish onboarding."
        button={{ children: buttonText, onClick: next }}
      />
    );
  }

  return (
    <IconPage
      title="Congratulations! We are processing your payment."
      subtitle={subtitle}
      button={{ children: buttonText, onClick: next }}
    />
  );
};
