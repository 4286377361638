import { useContext } from "react";

import {
  formatCurrency,
  Icons,
  Loader,
  RadioButton,
  RadioGroup,
  RadioOrCheckboxSecondTier,
  WizardStepperContext,
} from "@unchained/component-library";
import cn from "classnames";
import { Navigate } from "react-router-dom";

import { AccountAvatar } from "Components/Layout/Nav/Sidebar/OldSidebar/AccountSwitcher/accountHelpers";
import { ManifestStep } from "Routes/onboard/(shared)";
import { OnboardBasicContext } from "Routes/onboard/(utils)/context";
import { useOnboardNewOrg } from "Routes/onboard/(utils)/createAccountUtils";
import { individualFlowComplete } from "Routes/onboard/(utils)/flowCompletion";
import { useBasicQueryString } from "Routes/onboard/BasicQuery";
import { SKUS } from "Shared/api/v2";
import { getPurchaseable, usePurchasables } from "Shared/api/v2/hooks/purchasables";
import { CompleteOrgAccountTypeEnum } from "Specs/v1/getAccount/200";
import { COMPLETE_ORG_ACCOUNT_TYPE } from "Specs/v1/getOrg/200";
import { useEasyToasts } from "Utils/toasts";

export const formatDollars = (dollars: string | number) =>
  formatCurrency(parseFloat(dollars.toString()), "USD");

// For radio buttons
const avatar = (accountType: CompleteOrgAccountTypeEnum) => (
  <AccountAvatar accountType={accountType} className="mr-1" />
);

export const CreateYourAccount = () => {
  const info = useContext(OnboardBasicContext);
  const { goToNext } = useContext(WizardStepperContext);
  const { showErrorToast } = useEasyToasts();
  const { accountType: accountTypeIntention } = useBasicQueryString();
  const purchasablesQuery = usePurchasables();

  const { person, accountType, user } = info;
  const { IRA, BUSINESS, INDIVIDUAL, TRUST } = COMPLETE_ORG_ACCOUNT_TYPE;

  const hideProfile = info.personalOrg?.state !== "pending_payment";
  const basicProfileLive = info.personalOrg?.state === "live";
  const onboardingNewOrg = useOnboardNewOrg();

  // Preselect personal if it's available
  let type =
    accountType.value ||
    accountTypeIntention ||
    (!individualFlowComplete(info) ? INDIVIDUAL : undefined);

  if (!!type && type !== accountType.value) {
    accountType.set(type);
  }
  // But if it's hidden, set type to undefined
  if (hideProfile && type === INDIVIDUAL) {
    type = undefined;
  }

  const hasInProgressIra = !!info.memberships
    .map(m => m.org)
    .find(o => o.account_type === IRA && o.state !== "live");

  if (purchasablesQuery.isLoading) return <Loader className="h-screen w-full" />;
  if (purchasablesQuery.isError) {
    showErrorToast(purchasablesQuery.error);
    return <Navigate to="/home" />;
  }

  const { purchasables } = purchasablesQuery.data;

  const individualAcctPurchasable = getPurchaseable(purchasables, SKUS.INDIVIDUAL_ACCOUNT);
  const iraFee = getPurchaseable(purchasables, SKUS.IRA_ACCOUNT);
  const trustFee = getPurchaseable(purchasables, SKUS.TRUST_ACCOUNT);

  const isSignature = user?.signature;

  const onContinueClick = () => {
    if (user.signature) {
      onboardingNewOrg(type);
    } else {
      goToNext();
    }
  };

  return (
    <ManifestStep
      width="max-w-3xl"
      className="md:mt-12"
      actions={[{ children: "Continue", onClick: onContinueClick, disabled: !accountType.value }]}
      title={
        basicProfileLive
          ? "Create a new account"
          : "Congrats! Your personal information has been approved"
      }
      description={
        basicProfileLive
          ? "Would you like an IRA, business, or trust account?"
          : "Finish your personal account setup or add another account type. You will have the option to add more accounts later."
      }
    >
      <div className="flex flex-col gap-7">
        <RadioGroup
          onChange={e => accountType.set(e.target.value as CompleteOrgAccountTypeEnum)}
          name="accountType"
          className={cn("grid grid-cols-1 gap-3 md:grid-cols-2")}
          value={type}
        >
          {hideProfile ? null : (
            <RadioButton
              label="Personal"
              data-testid="individual-radio"
              customIcon={avatar(INDIVIDUAL)}
              value={INDIVIDUAL}
              description={
                <RadioOrCheckboxSecondTier>
                  <div className="mb-2 text-lg font-semi text-gray-700">
                    {formatDollars(individualAcctPurchasable.usdPrice)}/year
                  </div>
                  <div>
                    Secure and trade bitcoin in a personal vault, and access the inheritance
                    protocol.
                  </div>
                </RadioOrCheckboxSecondTier>
              }
            />
          )}
          <RadioButton
            label="Bitcoin IRA"
            data-testid="ira-radio"
            value={IRA}
            disabled={hasInProgressIra}
            tooltip={
              hasInProgressIra
                ? "You can only have one in-progress IRA application at a time."
                : undefined
            }
            customIcon={avatar(IRA)}
            description={
              <RadioOrCheckboxSecondTier
                upperLeft={
                  isSignature ? (
                    <h2 className="flex flex-row items-center gap-2 text-lg font-semi text-gold-600">
                      <span>Included with Signature</span>
                      <Icons.Star />
                    </h2>
                  ) : (
                    <div className="mb-2 text-lg font-semi text-gray-700">
                      {formatDollars(iraFee.usdPrice)}/year
                    </div>
                  )
                }
                lowerLeft={
                  <div>Hold the keys to tax-advantaged bitcoin with an all-in-one IRA account.</div>
                }
              ></RadioOrCheckboxSecondTier>
            }
          />
          <RadioButton
            label="Business"
            data-testid="business-radio"
            value={BUSINESS}
            customIcon={avatar(BUSINESS)}
            description={
              <RadioOrCheckboxSecondTier>
                <div className="mb-2 text-lg font-semi text-gray-700">Variable pricing</div>
                <div>
                  Bitcoin custody and services for businesses, non-profits, and Solo 401(k)s.
                </div>
              </RadioOrCheckboxSecondTier>
            }
          />
          <RadioButton
            label="Trust"
            data-testid="trust-radio"
            value={TRUST}
            customIcon={avatar(TRUST)}
            description={
              <RadioOrCheckboxSecondTier
                upperLeft={
                  isSignature ? (
                    <h2 className="flex flex-row items-center gap-2 text-lg font-semi text-gold-600">
                      <span>Included with Signature</span>
                      <Icons.Star />
                    </h2>
                  ) : (
                    <div className="mb-2 text-lg font-semi text-gray-700">
                      {formatDollars(trustFee.usdPrice)}/year
                    </div>
                  )
                }
                lowerLeft={
                  <div>
                    Title your bitcoin vaults and loans to your trust to ensure your estate plan is
                    executed according to your plan.
                  </div>
                }
              ></RadioOrCheckboxSecondTier>
            }
          />
        </RadioGroup>
      </div>
    </ManifestStep>
  );
};
