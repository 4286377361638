import { omit } from "lodash";

import { IraOrg } from "Specs/v1/getIraOrg/200";
import {
  CompleteOrg,
  completeOrgPersonEstimatedWealthEnum,
  completeOrgPersonEstimatedWealthNotesEnum,
  CompleteOrgPersonEstimatedWealthNotesEnum,
  completeOrgPersonIncomeEnum,
  completeOrgPersonIncomeSourcesEnum,
  CompleteOrgPersonIncomeSourcesEnum,
} from "Specs/v1/getOrg/200";
import { camelCaseKeys } from "Utils/objects";
import { array, object, string } from "Utils/yup";

import { getOwner } from "../(ira)/steps/IraInfo/(utils)/getters";

/* SSN */

export const initSsn = (org: CompleteOrg | IraOrg) => {
  const person = "person" in org ? org.person : getOwner(org as IraOrg);

  return {
    nationality: person.nationality,
    ssn: person.ssn,
  };
};

export const validateSsn = object({
  nationality: string().min(1).required().label("Nationality"),
  ssn: string().when("nationality", {
    is: "US",
    then: string().ssn().label("Social security number").required(),
    otherwise: string().min(9).max(15).label("Tax identification number").required(),
  }),
});

/* Employment */

export const initEmployment = (org: CompleteOrg | IraOrg) => {
  const { employment } = "account_type" in org ? org.person : getOwner(org as IraOrg);

  return omit(camelCaseKeys(employment || {}), "allowed_actions", "allowedActions");
};

export const validateEmployment = object({
  status: string().label("Employment status").required(),
  industry: string().required().label("Industry"),
  jobTitle: string()
    .required()
    .label("Job title")
    // This requirement comes from Solera
    .test(
      "jobTitleIsComplete",
      "More specific job title required. What do you do specifically?",
      (value: string) =>
        ["Owner", "CEO"].every(v => v.toLowerCase() !== value?.trim()?.toLowerCase())
    ),
  employer: string().required().label("Employer"),
});

/* Personal Income */

export const addDollarSignsToMoneyRange = (moneyRange: string) => {
  // The first two $$ are an escaped dollar sign.
  // The last, is part of the replacement interpolation, and represents the captured group.
  // The regex finds number groups (with commas), surrounded by word breaks,
  // and adds a dollar sign before each.
  return moneyRange.replace(/\b(\d[\d,]*)\b/g, "$$$1");
};

export const initIncome = (org: CompleteOrg) => ({
  income: org.person.income,
  income_sources: (org.person.income_sources || "")
    .split(",")
    .map(s => s.trim())
    .filter(
      source =>
        Boolean(source) &&
        completeOrgPersonIncomeSourcesEnum.includes(source as CompleteOrgPersonIncomeSourcesEnum)
    ),
});

export const validateIncome = object({
  income: string().oneOf(completeOrgPersonIncomeEnum).label("Income range").required(),
  income_sources: array(string()).min(1).required().label("Income sources"),
});

/* Estimated wealth */

export const initWealth = (org: CompleteOrg) => ({
  estimated_wealth: org.person.estimated_wealth,
  estimated_wealth_notes: (org.person.estimated_wealth_notes || "")
    .split(",")
    .map(s => s.trim())
    .filter(
      source =>
        Boolean(source) &&
        completeOrgPersonEstimatedWealthNotesEnum.includes(
          source as CompleteOrgPersonEstimatedWealthNotesEnum
        )
    ),
});

export const validateWealth = object({
  estimated_wealth: string()
    .oneOf(completeOrgPersonEstimatedWealthEnum)
    .label("Digital assets")
    .required(),
  estimated_wealth_notes: array(string()).min(1).required().label("Digital asset sources"),
});

/** Whole advanced org */

export const advancedIndividualOrgValid = (org: CompleteOrg) =>
  validateSsn.isValidSync(initSsn(org)) &&
  validateEmployment.isValidSync(initEmployment(org)) &&
  validateIncome.isValidSync(initIncome(org)) &&
  validateWealth.isValidSync(initWealth(org));
