import { FC, ReactNode, useState } from "react";

import {
  Dropdown,
  HeroIcon,
  IconProps,
  Icons,
  InputLabel,
  Loader,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  TextArea,
} from "@unchained/component-library";
import cn from "classnames";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";

import { Link } from "Components/Link";
import { ClientReportsState } from "Reducers/clientReportReducer";
import { RootState } from "Reducers/index";
import { useCurrentOrgDeprecated, useCurrentUser } from "Redux/selectors/hooks";
import { ClientReportsAPI } from "Shared/api/v2";
import { AppModalManager } from "Shared/components/Modals";
import { ClientReportData } from "Specs/v2/components";

import { useMaskedAccount } from "./useMaskedAccount";

const getSystemInfo = () => {
  try {
    return {
      userAgent: navigator?.userAgent,
      windowSize: {
        width: window?.innerWidth,
        height: window?.innerHeight,
      },
      timezone: Intl?.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone,
    };
  } catch (err) {
    console.error(err);
    return {};
  }
};

const IconContent = ({
  icon,
  color,
  text,
}: {
  icon: FC<IconProps>;
  color: "red" | "green";
  text: ReactNode;
}) => (
  <div className="my-2 flex h-full flex-col items-center justify-center">
    <div className="flex flex-col items-center gap-6">
      <HeroIcon color={color} Icon={icon} size="lg" />
      <p className="[&>a]:!font-bold [&>a]:!underline">{text}</p>
    </div>
  </div>
);

export const ClientReportModal = ({ error }: { error?: Record<string, unknown> }) => {
  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const { errors, pathHistory, extra, requests } = useSelector<RootState>(
    state => state.clientReports
  ) as ClientReportsState;

  const maskedAccount = useMaskedAccount();
  const [description, setDescription] = useState("");
  const [behavior, setBehavior] = useState("");
  const [urgency, setUrgency] = useState<string>();
  const [state, setState] = useState<"error" | "success">();

  const submitReport = useMutation((data: ClientReportData) => ClientReportsAPI.Create(data), {
    onSuccess: () => {
      setState("success");
    },
    onError: (error: unknown) => {
      console.error(error);
      setState("error");
    },
  });

  const fullyLoaded = !!maskedAccount && !submitReport.isLoading;

  let content;
  if (state === "success") {
    content = (
      <IconContent
        color="green"
        icon={Icons.CheckmarkCircle}
        text={
          <>
            Thank you. Your report has been sent. If your problem persists, please reach out to{" "}
            <a href="mailto:help@unchained.com" target="_blank" rel="noopener noreferrer">
              help@unchained.com
            </a>
            .
          </>
        }
      />
    );
  } else if (state === "error") {
    const text = (
      <>
        Report sharing failed. Please <Link to="mailto:help@unchained.com">let us know</Link>.
      </>
    );
    content = <IconContent color="red" icon={Icons.AlertCircle} text={text} />;
  } else if (fullyLoaded) {
    content = (
      <>
        <div className="prose">
          <p>
            Fill in the details of your issue below, and then submit to send a report to Unchained.
            The report will include{" "}
            <strong>
              <em>redacted</em>
            </strong>{" "}
            basic info about your current app state, including:
          </p>
          <ul>
            <li>Errors that may recently have occured.</li>
            <li>Your recent Unchained navigation history.</li>
            <li>
              Account details <em>excluding personally-identifying information</em> (but including
              unique IDs specific administrators can use to follow up with you and get more
              information).
            </li>
            <li>Recent API requests (omitting request and response content).</li>
            <li>Anonymized information relevant to the context of the flow you are in.</li>
          </ul>
        </div>
        <div className="mt-8 flex w-full flex-col gap-4">
          <Dropdown
            label="How urgent is this issue?"
            fullWidth
            onSelect={opt => setUrgency(opt.value as string)}
            options={[
              { label: "Urgent. I'm blocked!", value: "urgent" },
              { label: "I'm not urgently blocked", value: "not_urgent" },
              { label: "This is just a feature request", value: "feature_request" },
            ]}
          />
          <div>
            <InputLabel>What were you trying to do?</InputLabel>
            <TextArea
              fullWidth
              placeholder="What were you trying to do?"
              onChange={e => setDescription(e.target.value)}
              value={description}
            />
          </div>
          <div>
            <InputLabel>What happened?</InputLabel>
            <TextArea
              fullWidth
              placeholder="What happened?"
              onChange={e => setBehavior(e.target.value)}
              value={behavior}
            />
          </div>
        </div>
      </>
    );
  } else {
    content = <Loader className="my-6 h-full w-full" size="md" />;
  }

  const onSendReport = () => {
    try {
      const json = {
        urgency,
        behavior,
        userUuid: maskedAccount?.userUuid,
        orgUuid: maskedAccount?.orgUuid,
        triggeredByError: !!error,
        errors: [error, ...errors.slice(0, 19)].filter(Boolean),
        pathHistory: pathHistory.slice(0, 30).map(p => p.path),
        system: getSystemInfo(),
        extra,
        requestHistory: requests.slice(0, 80).map(r => {
          const base = `[${r.code}] ${r.method?.toUpperCase()} ${r.path}`;
          if (r.responseData) {
            const responseDataString = Object.entries(r.responseData)
              .filter(([_, v]) => !!v)
              .map(([k, v]) => `${k}: "${v}"`)
              .join(", ");
            return `${base} - ${responseDataString}`;
          } else {
            return base;
          }
        }),
        account: maskedAccount,
      };
      submitReport.mutate({ description, report: json });
    } catch (err) {
      setState("error");
    }
  };

  return (
    <Modal onDismiss={AppModalManager.close} className={!!state && "sm:min-w-[350px]"}>
      <ModalHeader>
        <ModalTitle
          subtitle={state ? undefined : "Did something go wrong? Send a secure report to Unchained"}
        >
          {state === "success"
            ? "Info reported"
            : state === "error"
            ? "Report failed"
            : "Share more about your problem"}
        </ModalTitle>
      </ModalHeader>
      <ModalContent>{content}</ModalContent>
      <ModalFooter
        actions={
          !!state
            ? [{ children: "OK", onClick: AppModalManager.close }]
            : [
                {
                  children: "Send report",
                  disabled:
                    !!state ||
                    !fullyLoaded ||
                    submitReport.isLoading ||
                    [description, urgency, behavior].some(f => !f),
                  onClick: onSendReport,
                },
                {
                  children: "Cancel",
                  onClick: AppModalManager.close,
                },
              ]
        }
      />
    </Modal>
  );
};

export const openClientReportModal = (error?: Record<string, unknown>) =>
  AppModalManager.open(() => <ClientReportModal error={error} />);

const clientReportApiRequestsSelector = (state: RootState) => state.clientReports.requests;

/** Return true if user or org is signature or CO */
export const useIsSignatureOrCO = () => {
  const loggedIn = !!window.TREFOIL_USER?.uuid;
  const currentOrg = useCurrentOrgDeprecated();
  const user = useCurrentUser();

  if (!loggedIn || !currentOrg || !user) return false;

  const hasCo = !!currentOrg.concierge_onboarding;
  const isSignature = !!user.signature;

  return hasCo || isSignature;
};

/** Return true if the user has made any failed/error requests since loading/refreshing. */
export const useMadeFailedRequests = () => {
  const apiRequests = useSelector(clientReportApiRequestsSelector);
  return apiRequests.some(r => r.code >= 400);
};

export const useShowClientReports = () => {
  const isSig = useIsSignatureOrCO();
  const madeFailedRequests = useMadeFailedRequests();
  return madeFailedRequests || isSig;
};

export const ClientReportLink = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  const showClientReports = useShowClientReports();

  const classes = cn("cursor-pointer underline", className);

  if (showClientReports) {
    return (
      <a onClick={() => openClientReportModal()} className={classes}>
        {children}
      </a>
    );
  }

  return (
    <a href="mailto:help@unchained.com" target="_blank" rel="noreferrer" className={classes}>
      {children}
    </a>
  );
};
