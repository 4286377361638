import React, { useContext } from "react";

import { TableRow } from "@mui/material";
import { KeyGrid } from "@unchained/component-library";

import { mapKeyRoleToKeyOwner } from "Components/Shared/Elements/Keys/utils";
import { SummaryTableCell } from "Components/Shared/Elements/Summary/SummaryTableCell";
import { WithdrawModalContext } from "Components/VaultsView/Show/WithdrawModal";
import { getSelectedAccountKeysFromSigningKeys } from "Components/VaultsView/Show/WithdrawModal/functions";
import { useMemoizedState } from "Redux/selectors/hooks";

export const SigningKeysRow = () => {
  const orgUUID = useMemoizedState("vaults.vaultShow.vault.owner.uuid");
  const { signingKeys } = useContext(WithdrawModalContext);

  const selectedAccountKeys = getSelectedAccountKeysFromSigningKeys(signingKeys);

  return (
    <TableRow>
      <SummaryTableCell component="th">Signing keys</SummaryTableCell>
      <SummaryTableCell className="!py-3">
        <KeyGrid
          size="xs"
          keys={selectedAccountKeys.map((key, index) => {
            const isSharedWithMe = orgUUID && "owner" in key && key.owner?.uuid !== orgUUID;
            const ownerType = mapKeyRoleToKeyOwner({ role: key.role, isSharedWithMe });
            return {
              label: key.name,
              ownerType,
            };
          })}
        />
      </SummaryTableCell>
    </TableRow>
  );
};
