import React from "react";

/* eslint-disable-next-line no-restricted-imports */
import { Business as BusinessIcon, Person as UserIcon } from "@mui/icons-material";

import {
  accountKeyLabel,
  dateLabel,
  loanLabel,
  operationLabel,
  orgLabel,
  orgLegalLabel,
  timeLabel,
  userLabel,
  vaultLabel,
} from "Utils/labels";

import { KeyIcon } from "../Icons/KeyIcon";
import { LoanIcon } from "../Icons/LoanIcon";
import { VaultIcon } from "../Icons/VaultIcon";

const BaseLabel = ({ text, Icon }) => {
  return (
    <span>
      {Boolean(Icon) && (
        <span>
          <Icon /> &nbsp;{" "}
        </span>
      )}
      {text}
    </span>
  );
};

const IconComponent = (useDefaultIcon, ExplicitIcon, DefaultIcon) => {
  if (ExplicitIcon) {
    return ExplicitIcon;
  } else {
    return useDefaultIcon ? DefaultIcon : null;
  }
};

export const UserLabel = ({ user, icon, Icon }) => {
  return <BaseLabel text={userLabel(user)} Icon={IconComponent(icon, Icon, UserIcon)} />;
};

export const OrgLabel = ({ org, icon, Icon }) => {
  return (
    <BaseLabel
      text={orgLabel(org)}
      Icon={IconComponent(icon, Icon, org.personal ? UserIcon : BusinessIcon)}
    />
  );
};

export const OrgLegalLabel = ({ org, icon, Icon }) => {
  return (
    <BaseLabel
      text={orgLegalLabel(org)}
      Icon={IconComponent(icon, Icon, org.personal ? UserIcon : BusinessIcon)}
    />
  );
};

export const AccountKeyLabel = ({ account_key, icon, Icon }) => {
  return (
    <BaseLabel text={accountKeyLabel(account_key)} Icon={IconComponent(icon, Icon, KeyIcon)} />
  );
};

export const VaultLabel = ({ vault, icon, Icon }) => {
  return <BaseLabel text={vaultLabel(vault)} Icon={IconComponent(icon, Icon, VaultIcon)} />;
};

export const LoanLabel = ({ loan, icon, Icon }) => {
  return <BaseLabel text={loanLabel(loan)} Icon={IconComponent(icon, Icon, LoanIcon)} />;
};

export const TimeLabel = ({ time, short }) => {
  return timeLabel(time, short);
};

export const DateLabel = ({ date }) => {
  return dateLabel(date);
};

export const OperationLabel = ({ operation, icon, Icon }) => {
  return <BaseLabel text={operationLabel(operation)} Icon={IconComponent(icon, Icon, null)} />;
};
