import React from "react";

/* eslint-disable-next-line no-restricted-imports */
import { CloudUpload as UploadIcon } from "@mui/icons-material";
import cn from "classnames";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";

import styles from "./FileUpload.module.scss";

const DEFAULT_MIME_TYPES = "image/jpg, image/jpeg, image/png, application/pdf";
const BYTES_PER_MB = 1048576;

/**
 * Converts a string of mime types into a comma separated list of file extensions.
 * @param {string} mimeTypes a string of mime types separated by commas e.g: "image/jpg, image/jpeg, image/png"
 * @returns {string} file extensions associated with accepted mimetypes (e.g: ".pdf, .jpg, .png")
 */
const acceptedFileExtensions = mimeTypes =>
  mimeTypes
    .split(",")
    .map(ext => ext.replace(/^.+\//, "."))
    .join(", ");

const AcceptedFileMessage = ({ mimeTypes, maxFileSize }) => {
  return (
    <div className={styles.acceptedFileExtensionsMessage}>
      Accepted file types: {acceptedFileExtensions(mimeTypes)}
      <br />
      Max file size: {maxFileSize / BYTES_PER_MB} MB
    </div>
  );
};

const RejectedFileMessage = ({ rejected_file, maxFileSize, mimeTypes }) => {
  if (rejected_file.size > maxFileSize) {
    return (
      <div className="has-danger invalid-feedback">
        File is too big. Max file size is {maxFileSize / BYTES_PER_MB} MB.
      </div>
    );
  }
  if (!mimeTypes.includes(rejected_file.type)) {
    return (
      <div className="has-danger invalid-feedback">
        File type is not supported. Accepted file types are: {acceptedFileExtensions(mimeTypes)}
      </div>
    );
  }
  return <div className="has-danger invalid-feedback">We couldn't process your file.</div>;
};

class FileUploadBase extends React.Component {
  static propTypes = {
    mimeTypes: PropTypes.string.isRequired,
    maxFileSize: PropTypes.number.isRequired,
    uploadMessage: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    updateDocument: PropTypes.func.isRequired,
    required: PropTypes.bool.isRequired,
    className: PropTypes.string,
  };

  state = {
    accepted_files: [],
    rejected_files: [],
  };

  static defaultProps = {
    mimeTypes: DEFAULT_MIME_TYPES,
    maxFileSize: 10 * BYTES_PER_MB, // 10 MB
    uploadMessage: `Click OR drag-and-drop a file.`,
    title: "Document",
    required: true,
  };

  render() {
    const { mimeTypes, maxFileSize, uploadMessage, required, className } = this.props;
    const { accepted_files, rejected_files } = this.state;
    if (accepted_files.length > 0) {
      return null;
    }
    return (
      <div>
        <Dropzone
          className={cn(styles.dropzone, className)}
          activeStyle={{
            borderStyle: "solid",
            backgroundColor: "#eee",
          }}
          onDrop={this.attachFile}
          multiple={false}
          minSize={1}
          maxSize={maxFileSize}
          accept={mimeTypes}
        >
          <p className="mb-4">
            {uploadMessage}
            {required && "*"}
          </p>
          <UploadIcon classes={{ root: styles.uploadIcon }} />
          <AcceptedFileMessage mimeTypes={mimeTypes} maxFileSize={maxFileSize} />
        </Dropzone>

        {rejected_files.length > 0 && (
          <RejectedFileMessage
            rejected_file={rejected_files[0]}
            maxFileSize={maxFileSize}
            mimeTypes={mimeTypes}
          />
        )}
      </div>
    );
  }

  singleAcceptedFile = (accepted_files, rejected_files) => {
    return rejected_files.length === 0 && accepted_files.length === 1;
  };

  attachFile = (accepted_files, rejected_files) => {
    const { updateDocument } = this.props;
    const stateUpdate = { accepted_files, rejected_files };
    if (this.singleAcceptedFile(accepted_files, rejected_files)) {
      const document = this.document(accepted_files[0]);
      this.setState(stateUpdate, () => {
        updateDocument(document);
      });
    } else {
      this.setState(stateUpdate);
    }
  };

  document = file => {
    const { title } = this.props;
    return {
      title,
      name: file.name,
      thumbnail_data: file.preview,
      size: file.size,
      newContent: file,
    };
  };
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = {};

const FileUpload = connect(mapStateToProps, mapDispatchToProps)(FileUploadBase);

export { FileUpload };
