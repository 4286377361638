import React, { Component } from "react";

/* eslint-disable no-restricted-imports */
import { Button } from "@unchained/component-library";
import get from "lodash/get";
import moment from "moment";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { spendingOperationSelector } from "Redux/selectors/spendingSelectors";
import { AppModalManager } from "Shared/components/Modals/AppModalManager";
import { formatCurrency } from "Utils/strings";
import { presentTransactionVerb } from "Utils/verbs";
import { verificationScript } from "Utils/verification";

import { VerificationRecordModal } from "../../verifications/VerificationRecordModal";
import wizStyles from "./SpendingWizard.module.scss";
import { TransactionDetails } from "./TransactionDetails";

export const destinationScript = (spending, unit, operation) => {
  const destinationOutput = spending.transaction.outputs[0];

  if (operation.type.includes("liquidation")) {
    return (
      <p className="mb-1">
        To: Unchained address <span className="font-bold">{verificationScript}</span>
      </p>
    );
  }

  // Note that checking of product_type can be removed once the api is updated to not return an object
  // when a product doesn't exist.
  const relatedProductExists = Boolean(destinationOutput?.product?.product_type);

  return (
    <>
      {relatedProductExists ? (
        <>
          <p className="mb-1">
            To: {destinationOutput.product.product_type}{" "}
            <span className="font-bold">{destinationOutput.product.name}</span> with ID{" "}
            <span className="font-bold">{destinationOutput.product.uuid}</span>
          </p>
          <p className="mb-1">
            With address starting:{" "}
            <span className="font-bold">{destinationOutput.address.slice(0, 6)}</span>
          </p>
        </>
      ) : (
        <>
          <p className="mb-1">
            To: address starting:{" "}
            <span className="font-bold">{destinationOutput.address.slice(0, 6)}</span>
          </p>
        </>
      )}
      <p className="mb-1">
        And ending:{" "}
        <span className="font-bold">
          {destinationOutput.address.slice(destinationOutput.address.length - 6)}
        </span>
      </p>
    </>
  );
};

export const createVerificationScript = (operation, unit, accountType, accountUUID, spending) => {
  const destinationOutput = operation.btc_transaction_requests[0].transaction.outputs[0];
  return (
    <div>
      <h2 className="mb-2 text-md font-bold text-gray-800">Verification script</h2>
      <div className="text-gray-800">
        <p className="mb-1">Today is {moment().format("MMMM Do YYYY")}.</p>{" "}
        <p className="mb-1">
          My name is [ <span className="font-bold">your full name</span> ].
        </p>
        <p className="mb-1">
          I am verifying my intent to {presentTransactionVerb(operation.type)} bitcoin with the
          following details:
        </p>
        <p className="mb-1">
          Amount:{" "}
          <span className="font-bold">
            {formatCurrency(destinationOutput.amount, 8)} {unit}
          </span>
        </p>
        <p className="mb-1">
          From: <span className="font-bold">{accountType}</span> with ID{" "}
          <span className="font-bold">{accountUUID}</span>
        </p>
        <p className="mb-1">{destinationScript(spending, unit, operation)}</p>
      </div>
    </div>
  );
};

export const canUserVerify = verification => {
  return verification?.allowed_actions?.includes("record_verification");
};

class VerificationStepBase extends Component {
  state = {
    showRecord: false,
  };

  static propTypes = {
    accountType: PropTypes.string,
    accountUUID: PropTypes.string,
    isModal: PropTypes.bool,
    next: PropTypes.any, // Todo: Update with more specifics.
    operation: PropTypes.shape({
      btc_transaction_requests: PropTypes.any, // Todo: Update with more specifics.
    }),
    verification: PropTypes.shape({
      username: PropTypes.string,
      allowed_actions: PropTypes.arrayOf(PropTypes.string),
    }),
    verificationRequired: PropTypes.bool,
    spending: PropTypes.shape({
      transaction: PropTypes.any, // Todo: Update with more specifics.
    }),
    unit: PropTypes.string,
  };

  componentDidMount() {
    const { verification, next } = this.props;
    if (!verification) {
      next();
    }
  }

  showRecordVerification = () => {
    const { operation, unit, accountType, accountUUID, spending } = this.props;
    return (
      <VerificationRecordModal
        verification={this.props.verification}
        script={createVerificationScript(operation, unit, accountType, accountUUID, spending)}
        afterUploaded={this.afterVerificationUploaded}
        isRequired={this.props.verificationRequired}
      />
    );
  };

  handleRecordVerification = () => {
    const { isModal } = this.props;

    if (isModal) {
      this.setState({ showRecord: true });
    } else {
      AppModalManager.open(() => this.showRecordVerification());
    }
  };

  afterVerificationUploaded = () => {
    const { next } = this.props;
    AppModalManager.close();
    if (next) next();
  };

  adminMustVerify = () => {
    const { verification } = this.props;
    const verificationUsername = get(verification, "username");
    return verificationUsername === "admin";
  };

  renderTitle = () =>
    canUserVerify(this.props.verification) ? "Verify transaction" : "Waiting for verification";

  renderAction = () => {
    const { accountType, context_type, verification } = this.props;
    //isModal
    if (canUserVerify(verification)) {
      return (
        <Button
          style={{ justifySelf: "flex-end", marginTop: "1rem" }}
          fullWidth
          type="primary"
          onClick={this.handleRecordVerification}
        >
          Record verification
        </Button>
      );
    } else {
      if (accountType === "loan" && context_type === "liquidation") {
        return <p>We are waiting for Unchained staff to record this verification.</p>;
      } else {
        return <p>We are waiting for the {accountType} owner to record this verification.</p>;
      }
    }
  };

  render() {
    if (this.props.isModal) {
      return this.showRecordVerification();
    }

    return (
      <>
        <div className={wizStyles.card}>
          <div style={{ justifyContent: "flex-start" }}>
            <div>
              <h2>{this.renderTitle()}</h2>
              <p>This transaction requires a video verification to be recorded.</p>
            </div>
            {this.renderAction()}
          </div>
        </div>
        <div className={wizStyles.transactionDetails}>
          <TransactionDetails />
        </div>
      </>
    );
  }
}

export const VerificationStep = props => {
  const spendingProps = useSelector(spendingOperationSelector);
  return <VerificationStepBase {...props} {...spendingProps} />;
};
