import { createContext, ReactNode, useContext, useEffect, useState } from "react";

import { Button, Checkbox, Icons, WizardStepperContext } from "@unchained/component-library";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import unchainedTextLogo from "Assets/images/TextLogoDark.svg";
import { Link } from "Components/Link";
import { StepColumn, StepTitle, StepTop } from "Components/Shared/Layouts/FullPageWizard";
import { orgQueryKeys, useIraOrg } from "Shared/api";
import { IRAPlansAPI } from "Shared/api/v2";
import { useIraAgreement } from "Shared/api/v2/hooks/irasPlans";
import { IraOrg } from "Specs/v1/getIraOrg/200";
import { useEasyToasts } from "Utils/toasts";

const useIRAConsent = (org: IraOrg, uuid: string, onConsentSuccess?: () => void) => {
  const queryClient = useQueryClient();
  const [clickedLink, setClickedLink] = useState(false);
  const [checkedBox, setCheckedBox] = useState(false);
  const { showErrorToast } = useEasyToasts();
  const consented = !!org.iraPlan.fortisAgreementAcceptedAt;

  const iraAgreementQuery = useIraAgreement(uuid);

  const disclosure = iraAgreementQuery.data?.disclosure;
  const consent = iraAgreementQuery.data?.consent;

  // Use use-effect pattern to trigger onConsentSuccess *after*
  // the mutation has successfully propagated through React Query
  // (if the mutation has just fired).
  useEffect(() => {
    if (consented && checkedBox) onConsentSuccess();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consented, checkedBox]);

  const { mutate, isLoading: consentLoading } = useMutation(
    () => IRAPlansAPI.ConsentToAgreement(uuid),
    {
      onSettled: () => queryClient.invalidateQueries(orgQueryKeys.showIra(uuid)),
      onError: err => showErrorToast(err),
    }
  );

  const consentToAgreement = () => {
    if (consented) {
      onConsentSuccess?.();
    } else {
      mutate(undefined);
    }
  };

  return {
    consented,
    checkedBox,
    setCheckedBox,
    clickedLink,
    setClickedLink,
    consentToAgreement,
    consentLoading,
    disclosure,
    consent,
  };
};

type IRAConsentContextType = ReturnType<typeof useIRAConsent>;

const IRAConsentContext = createContext<IRAConsentContextType | undefined>(undefined);

const IRAConsentProvider = ({
  org,
  uuid,
  children,
  onConsentSuccess,
}: {
  org: IraOrg;
  uuid: string;
  children: (context: IRAConsentContextType) => ReactNode;
  onConsentSuccess?: () => void;
}) => {
  const value = useIRAConsent(org, uuid, onConsentSuccess);

  return <IRAConsentContext.Provider value={value}>{children(value)}</IRAConsentContext.Provider>;
};

const IRAConsentContents = () => {
  const {
    consent,
    consented,
    clickedLink,
    setClickedLink,
    checkedBox,
    disclosure,
    consentLoading,
    setCheckedBox,
  } = useContext(IRAConsentContext);

  return (
    <>
      <p className="text-sm font-semi">
        Please review the following documents that require authorization from our banking partner,
        Fortis:
      </p>
      <div className="flex justify-start">
        <Link className="btn btn-text" to={disclosure?.uri} onClick={() => setClickedLink(true)}>
          <Icons.Link01 /> Fortis IRA disclosure
        </Link>
      </div>
      <div className="prose">{consent?.text}</div>
      {clickedLink ? null : (
        <p className="text-xs font-med text-yellow-600">
          Open and review the PDF link before you can sign.
        </p>
      )}
      <div className="flex justify-start">
        <Checkbox
          noCard
          tooltip={clickedLink ? undefined : "You must review the documents before you can sign."}
          name="signed"
          checked={checkedBox || consented}
          disabled={consentLoading || consented || !clickedLink}
          onChange={() => setCheckedBox(!checkedBox)}
          label="Check to Sign"
        />
      </div>
    </>
  );
};

/** IRA consent step in onboarding wizard */
export const IRAConsentStep = ({ org }: { org: IraOrg }) => {
  const { uuid } = useParams<{ uuid: string }>();
  const { goToFirstReadyStep } = useContext(WizardStepperContext);

  return (
    <IRAConsentProvider org={org} uuid={uuid} onConsentSuccess={goToFirstReadyStep}>
      {({ consented, checkedBox, consentToAgreement, disclosure }) => (
        <StepColumn
          width="wide"
          actions={[
            {
              children: "Continue to checkout",
              onClick: consentToAgreement,
              disabled: !consented && !checkedBox,
            },
          ]}
          loading={!disclosure}
        >
          <StepTop>
            <img src={unchainedTextLogo} alt="unchained text logo" />
            <StepTitle>Consent required</StepTitle>
          </StepTop>
          <IRAConsentContents />
        </StepColumn>
      )}
    </IRAConsentProvider>
  );
};

export const IRAConsentDash = ({ uuid }: { uuid: string }) => {
  const iraQuery = useIraOrg(uuid);
  const org = iraQuery.data?.org;
  if (!org) return null;

  return (
    <IRAConsentProvider org={org} uuid={uuid}>
      {({ consented, checkedBox, consentToAgreement }) => (
        <div className="flex h-screen w-full items-center justify-center">
          <div className="flex max-w-4xl flex-col gap-8">
            <h1>Consent required</h1>
            <IRAConsentContents />
            <Button
              type="primary"
              onClick={consentToAgreement}
              disabled={!consented && !checkedBox}
            >
              Get started
            </Button>
          </div>
        </div>
      )}
    </IRAConsentProvider>
  );
};
