import { createContext, useMemo } from "react";

import { ErrorBoundary } from "Components/ErrorBoundaries/ErrorBoundary";
import { useNavigate } from "Components/Link";
import { FullPageWizard } from "Components/Shared/Layouts/FullPageWizard";
import { CloseButton } from "Components/Shared/Layouts/FullPageWizard/components";
import { LoadingContextProvider, useLoadingContext } from "Contexts/LoadingContext";
import { ShippingAddressStep, ShippingInfo } from "Routes/onboard/(shared)/ShippingAddressStep";
import { GetSetObj, hideSkippedSteps, useMakeGetSetObj } from "Routes/onboard/(utils)";
import { withAccount } from "Shared/api";
import { CompleteOrg, CompleteOrgStateEnum } from "Specs/v1/getAccount/200";
import { useAndRemoveQueryParams } from "Utils/uris";

import SignatureCheckout from "./SignatureUpgradeStep";

export type SignatureUpgradeContextType = {
  from?: string;
  org?: CompleteOrg;
  shippingInfo?: GetSetObj<ShippingInfo | undefined>;
  paymentProcessing?: GetSetObj<boolean>;
};

export const SignatureUpgradeContext = createContext<SignatureUpgradeContextType | undefined>(
  undefined
);

const getSignatureUpgradeManifest = (
  org: CompleteOrg,
  context: SignatureUpgradeContextType,
  paymentProcessing: GetSetObj<boolean>,
  goBack: () => void
) => {
  return hideSkippedSteps([
    {
      title: "Signature checkout",
      AlternateIcon: () => (paymentProcessing.value ? undefined : <CloseButton onClose={goBack} />),
      complete: false,
      steps: [
        {
          title: "Shipping address",
          Component: () => <ShippingAddressStep org={org} shippingInfo={context.shippingInfo} />,
          complete: context.shippingInfo.value,
        },
        {
          title: "Payment",
          Component: () => <SignatureCheckout />,
          complete: false,
        },
      ],
    },
  ]);
};

const SignatureUpgradeStepperBase = ({ org }: { org: CompleteOrg }) => {
  const { from } = useAndRemoveQueryParams("from");
  const shippingInfo = useMakeGetSetObj<ShippingInfo | undefined>(undefined);
  const paymentProcessing = useMakeGetSetObj(false);
  const navigate = useNavigate();
  const loading = useLoadingContext();

  const context = useMemo(
    () => ({ from, org, shippingInfo, paymentProcessing }),
    [from, org, shippingInfo, paymentProcessing]
  );

  const manifest = useMemo(
    () =>
      getSignatureUpgradeManifest(org, context, paymentProcessing, () => navigate(from || "/home")),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [context]
  );

  return (
    <SignatureUpgradeContext.Provider value={context}>
      <FullPageWizard manifest={manifest} desktopLayout="top" />
    </SignatureUpgradeContext.Provider>
  );
};

const SignatureUpgradeStepper = withAccount(({ currentOrg }) => (
  <LoadingContextProvider>
    <ErrorBoundary>
      <SignatureUpgradeStepperBase org={currentOrg} />
    </ErrorBoundary>
  </LoadingContextProvider>
));

export default SignatureUpgradeStepper;
