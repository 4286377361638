import { useContext, useEffect, useState } from "react";

/* eslint-disable-next-line no-restricted-imports */
import { ArrowBack } from "@mui/icons-material";
import { Radio, Table, TableBody, TableRow } from "@mui/material";
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { AddressParam, PaymentIntent } from "@stripe/stripe-js";
import { Button, ButtonProps, Loader } from "@unchained/component-library";
import cn from "classnames";
import { omit } from "lodash";
import { useMutation, useQueryClient } from "react-query";

import { useNavigate } from "Components/Link";
import {
  SummaryTableCell,
  SummaryTableHeaderCell,
} from "Components/Shared/Elements/Summary/SummaryTableCell";
import { StepColumn } from "Components/Shared/Layouts/FullPageWizard";
import { useLoadingContext } from "Contexts/LoadingContext";
import { CheckoutHeader } from "Routes/inheritance/(shared)/CheckoutHeader";
import { InvoiceReceiptShow } from "Routes/invoices/(shared)/InvoiceReceiptShow";
import { Shipping } from "Routes/invoices/(shared)/ShippingAddressShow";
import {
  applyCouponCodeToSubscription,
  getCompletedPaymentRedirectLink,
} from "Routes/invoices/(shared)/utils";
import { accountQueryKeys, OrgAPI, orgQueryKeys, useOrgPayingCustomer } from "Shared/api";
import { Invoice, InvoiceAPI, INVOICE_STATUS, SKUS, SubscriptionAPI } from "Shared/api/v2";
import { invoiceQueryKeys, useInvoice, usePatchInvoice } from "Shared/api/v2/hooks/invoices";
import { subscriptionQueryKeys, useSubscription } from "Shared/api/v2/hooks/subscriptions";
import { mapCreditCardBrandToLogoSvg } from "Utils/billing";
import { useStripeElementProps } from "Utils/stripe";
import { readableTime } from "Utils/time";
import { useEasyToasts } from "Utils/toasts";

import {
  CompletedPayment,
  FailedPayment,
  SignatureZeroCostPaymentCompleted,
} from "../../(shared)/PaymentFinalized";
import { InvoiceContext } from "../InvoiceStepper";

const { FAILED, SETTLED, COMPLETED, PENDING, CANCELED } = INVOICE_STATUS;

export const AddCreditCard = ({ handleError, onSetupIntentSuccess }) => {
  const [isFormValid, setFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handlePaymentChange = event => setFormValid(event.complete);
  const stripe = useStripe();
  const elements = useElements();
  const confirmSetup = async () => {
    try {
      setIsLoading(true);
      const { setupIntent } = await stripe.confirmSetup({
        elements,
        redirect: "if_required",
      });

      onSetupIntentSuccess(setupIntent.payment_method as string);
    } catch (err) {
      handleError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <PaymentElement
        onChange={handlePaymentChange}
        id="payment-element"
        options={{ layout: "tabs" }}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <Button
          type="primary"
          className="mt-3 px-6 py-3"
          disabled={!isFormValid}
          onClick={confirmSetup}
        >
          Save card
        </Button>
      )}
    </div>
  );
};

export const PaymentMethodSelection = ({
  payingCustomerQuery,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  existingPaymentMethods,
  handleError,
  invoice,
  paymentProcessing,
}) => {
  const [useExistingPaymentMethod, setUseExistingPaymentMethod] = useState(
    existingPaymentMethods.length > 0
  );
  const [clientSecret, setClientSecret] = useState(undefined);
  const { showErrorToast } = useEasyToasts();
  const [setupIntentInitiated, setSetupIntentInitiated] = useState(false);
  const elementProps = useStripeElementProps(clientSecret);
  const createSetupIntent = useMutation(() => OrgAPI.CreateSetupIntent(invoice.orgId), {
    onError: error => {
      showErrorToast("An error occurred while creating the credit card setup intent");
    },
  });

  const onSetupIntentSuccess = (paymentMethodId: string) => {
    setSelectedPaymentMethod(paymentMethodId);
    setUseExistingPaymentMethod(true);
    setClientSecret(undefined);
    payingCustomerQuery.refetch();
  };

  useEffect(() => {
    if (clientSecret || useExistingPaymentMethod) {
      setSetupIntentInitiated(true);
      return;
    }
    createSetupIntent.mutate(undefined, {
      onSuccess: data => {
        setClientSecret(data.clientSecret);
      },
      onSettled: () => {
        setSetupIntentInitiated(true);
      },
    });
  }, [useExistingPaymentMethod]);

  if (!setupIntentInitiated) {
    return (
      <div className="h-48 p-8">
        <Loader />
      </div>
    );
  }
  return (
    <div>
      {existingPaymentMethods.length > 0 && useExistingPaymentMethod && (
        <>
          <div className="my-4 text-md font-semi text-gray-700">
            Use an existing payment method:
          </div>
          {existingPaymentMethods.map((card, index) => (
            <div
              key={index}
              className={cn(
                "mb-4 flex cursor-pointer content-center items-center rounded-lg border-2 border-gray-200 p-2 hover:border-primary-500"
              )}
              onClick={() => setSelectedPaymentMethod(card.id)}
            >
              <img
                src={mapCreditCardBrandToLogoSvg(card.brand)}
                className="mr-2 inline max-h-[32px]"
                alt={card.brand}
              />
              <span>ending in {card.last4}</span>
              <span className="grow"></span>
              <Radio className="m" checked={selectedPaymentMethod === card.id} />
            </div>
          ))}
          <Button
            type="text"
            buttonType="button"
            onClick={() => {
              setUseExistingPaymentMethod(false);
              setSelectedPaymentMethod(undefined);
            }}
          >
            + new credit card
          </Button>
        </>
      )}

      {!useExistingPaymentMethod && existingPaymentMethods?.length > 0 && (
        <Button
          type="text"
          className="mb-4"
          buttonType="button"
          startIcon={<ArrowBack key={"arrow-back"} />}
          onClick={() => setUseExistingPaymentMethod(true)}
        >
          Back
        </Button>
      )}
      {(!useExistingPaymentMethod || !existingPaymentMethods?.length) && clientSecret && (
        <Elements {...elementProps}>
          <AddCreditCard handleError={handleError} onSetupIntentSuccess={onSetupIntentSuccess} />
        </Elements>
      )}
      <Loader
        className={
          (!useExistingPaymentMethod && !setupIntentInitiated) || paymentProcessing ? "" : "hidden"
        }
      />
    </div>
  );
};

/*
 * Checkout page for invoices (invoice uuid in route).
 * Collects credit card information and processes payment.
 * Currently used for account annual fee + CO checkout.
 */
const InvoiceCheckout = ({ invoice }: { invoice: Invoice }) => {
  const [paymentStatus, setPaymentStatus] = useState<PaymentIntent.Status | "failed">();
  const loading = useLoadingContext();
  const { from, shippingName, paymentProcessing } = useContext(InvoiceContext);
  const navigate = useNavigate();

  const { showErrorToast, showApiSuccessToast } = useEasyToasts();
  const patchInvoiceMutation = usePatchInvoice(invoice.id);
  const [submitted, setSubmitted] = useState(false);
  const subscription = useSubscription(invoice.subscriptionId, {
    enabled: !!invoice.subscriptionId,
  });

  const queryClient = useQueryClient();

  const payingCustomerQuery = useOrgPayingCustomer(invoice.orgId);

  const successContinueLink = getCompletedPaymentRedirectLink(
    invoice,
    from || `/onboard/${invoice.orgId}`
  );

  const hasDefaultPaymentMethod = !!subscription.data?.defaultPaymentMethod;

  const isZeroCostInvoice = parseFloat(invoice.amountDue) === 0;
  const isZeroCostSignatureInvoice =
    isZeroCostInvoice && invoice?.discounts?.some(discount => discount.name === "Signature");

  const paid = [SETTLED, COMPLETED].includes(invoice.status);
  const isPaymentInFinalState =
    ["failed", "succeeded"].includes(paymentStatus) ||
    (paid && hasDefaultPaymentMethod) ||
    isZeroCostSignatureInvoice;

  const shippingAddress = (invoice.shippingAddress || {}) as Required<
    typeof invoice.shippingAddress
  >;

  const isIraOrg = invoice.lineItems.some(item => item.sku === SKUS.IRA_ACCOUNT);

  const existingPaymentMethods = payingCustomerQuery.data?.paymentMethods || [];
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(undefined);
  const [useExistingPaymentMethod, setUseExistingPaymentMethod] = useState(true);

  const return_url = window.location.href;

  const applyCouponCode = async (couponCode: string) => {
    setPaymentStatus("processing");
    try {
      const sub = await applyCouponCodeToSubscription(
        couponCode,
        invoice.subscriptionId,
        invoice.orgId,
        invoice.shippingAddress
          ? { address: invoice.shippingAddress, name: invoice.shippingName }
          : undefined
      );
      showApiSuccessToast({
        title: "Coupon applied",
        description: "Your subscription has been updated.",
      });
      navigate(`/invoices/${sub.invoices[0].id}`);
    } catch (e) {
      showErrorToast(e, { title: "Invalid coupon code" });
      setPaymentStatus(undefined);
    }
  };

  useInvoice(invoice.id, {
    refetchInterval: 1000,
    enabled: submitted && !paid,
  });

  const onError = (err: unknown) => {
    console.error(err);
    showErrorToast("Something went wrong. Please try again.");
  };

  const modifyPaymentMethodMutation = useMutation(
    (paymentMethodId: string) => SubscriptionAPI.Patch(invoice.subscriptionId, { paymentMethodId }),
    {
      onMutate: () => setPaymentStatus("processing"),
      onError: error => {
        setPaymentStatus("failed");
      },
      onSuccess: () => {
        queryClient.invalidateQueries(subscriptionQueryKeys.get(invoice.subscriptionId));
        setPaymentStatus("succeeded");
        setSubmitted(true);
      },
      onSettled: () => {
        paymentProcessing.set(false);
      },
    }
  );

  const handlePayWithExistingCard = async () => {
    setPaymentStatus("processing");
    paymentProcessing.set(true);

    if (!invoice.clientSecret) {
      modifyPaymentMethodMutation.mutate(selectedPaymentMethod);
      return;
    }

    try {
      await InvoiceAPI.PayWithExistingPaymentMethod(invoice.id, selectedPaymentMethod);

      setPaymentStatus("succeeded");
      setSubmitted(true);
      paymentProcessing.set(false);
      queryClient.refetchQueries(invoiceQueryKeys.get(invoice.id));
      queryClient.invalidateQueries(subscriptionQueryKeys.get(invoice.subscriptionId));
    } catch (err) {
      handleError(err);
    }
  };

  const getActions = (): [ButtonProps, ButtonProps] | [ButtonProps] => {
    if (!isPaymentInFinalState) {
      return [
        {
          children: "Complete purchase",
          onClick: handlePayWithExistingCard,
          disabled: !selectedPaymentMethod || paymentStatus === "processing",
        },
      ];
    } else if (invoice.status === COMPLETED) {
      return [
        {
          children: "Done",
          type: "secondary",
          onClick: async () => {
            if (successContinueLink !== "/documents") {
              await Promise.all([
                queryClient.refetchQueries(subscriptionQueryKeys.get(invoice.subscriptionId)),
                queryClient.refetchQueries(accountQueryKeys.get),
                queryClient.refetchQueries(
                  isIraOrg ? orgQueryKeys.showIra(invoice.orgId) : orgQueryKeys.show(invoice.orgId)
                ),
              ]);
            }

            navigate(successContinueLink);
          },
        },
      ];
    } else if (paymentStatus === "failed" || invoice.status === FAILED) {
      return [
        {
          children: "Try again",
          type: "secondary",
          onClick: handleTryAgain,
        },
        {
          children: "Exit",
          type: "text",
          to: from || "/home",
        },
      ];
    }
  };

  const handleTryAgain = () => setPaymentStatus(undefined);
  const handleError = (err: unknown) => {
    setPaymentStatus("failed");
    paymentProcessing.set(false);
    onError(err);
  };

  const renderPaymentSection = () => {
    if (isPaymentInFinalState || isZeroCostSignatureInvoice) {
      if (isZeroCostSignatureInvoice) {
        return <SignatureZeroCostPaymentCompleted />;
      } else if (
        paymentStatus === "failed" ||
        invoice.status === FAILED ||
        invoice.status === CANCELED
      ) {
        return <FailedPayment />;
      }
      return (
        <div>
          <CompletedPayment />
          {invoice.status === PENDING && <Loader className="mt-4" />}
          {invoice.paymentMethod && (
            <Table className="my-4">
              <TableBody>
                <TableRow>
                  <SummaryTableHeaderCell>Paid at</SummaryTableHeaderCell>
                  <SummaryTableCell>{readableTime(invoice.paidAt)}</SummaryTableCell>
                </TableRow>
                <TableRow>
                  <SummaryTableHeaderCell>Payment method</SummaryTableHeaderCell>
                  <SummaryTableCell>
                    <img
                      src={mapCreditCardBrandToLogoSvg(invoice.paymentMethod?.brand)}
                      className="mr-2 inline max-h-[32px]"
                      alt={invoice.paymentMethod?.brand}
                    />
                    ending in {invoice.paymentMethod?.last4}
                  </SummaryTableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </div>
      );
    } else {
      return payingCustomerQuery.isLoading || subscription.isLoading ? (
        <Loader />
      ) : (
        <PaymentMethodSelection
          paymentProcessing={paymentStatus === "processing"}
          payingCustomerQuery={payingCustomerQuery}
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          existingPaymentMethods={existingPaymentMethods}
          handleError={handleError}
          invoice={invoice}
        />
      );
    }
  };

  return (
    <div className="max-w-4xl">
      <StepColumn width="full" className="pb-16" actions={getActions()} loading={loading.value}>
        <CheckoutHeader
          title="Payment"
          subtitle={
            isPaymentInFinalState ? undefined : "Please enter your payment information below"
          }
        />
        <div className="flex min-h-[36rem] flex-col gap-8 lg:flex-row">
          <div className="flex w-96 flex-1 flex-col">
            <h2 className="mb-6 text-gray-900">Purchase details</h2>

            <InvoiceReceiptShow
              invoice={invoice}
              applyCouponCode={
                !(isPaymentInFinalState || paymentProcessing.value) ? applyCouponCode : undefined
              }
            />
            {invoice.shippingAddress && (
              <Shipping name={invoice.shippingName} address={invoice.shippingAddress} />
            )}
          </div>

          <div className="w-96 flex-1">
            <h2 className="mb-6 text-gray-900">Payment information</h2>

            {renderPaymentSection()}
          </div>
        </div>
      </StepColumn>
    </div>
  );
};

export const InvoiceCheckoutWithElements = ({ invoice }) => {
  const { showErrorToast } = useEasyToasts();
  const [clientSecret, setClientSecret] = useState(invoice.clientSecret);
  const [setupIntentInitiated, setSetupIntentInitiated] = useState(false);
  const subscription = useSubscription(invoice.subscriptionId);
  const createSetupIntent = useMutation(() => OrgAPI.CreateSetupIntent(invoice.orgId), {
    onError: error => {
      showErrorToast("An error occurred while creating the credit card setup intent");
    },
  });
  const elementProps = useStripeElementProps(clientSecret);

  const paid = [SETTLED, COMPLETED].includes(subscription?.data?.invoices?.[0]?.status);

  const hasDefaultPaymentMethod = !!subscription.data?.defaultPaymentMethod;

  useEffect(() => {
    if (setupIntentInitiated) return;

    // if a card is somehow removed from a customer, and the invoice is paid
    // it may have a client secret. in this scenario let's create a new setup intent
    // that allows client to attach a card
    if ((invoice.clientSecret && !paid) || hasDefaultPaymentMethod) {
      setSetupIntentInitiated(true);
      return;
    }

    createSetupIntent.mutate(undefined, {
      onSuccess: data => {
        setClientSecret(data.clientSecret);
      },
      onSettled: () => {
        setSetupIntentInitiated(true);
      },
    });
  }, [invoice.clientSecret]);

  if (!setupIntentInitiated) {
    return <Loader />;
  }
  return (
    <Elements {...elementProps}>
      <InvoiceCheckout invoice={invoice} />
    </Elements>
  );
};
