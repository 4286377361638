import { Popover } from "@mui/material";
import { Avatar, AvatarProps, tailwindTheme } from "@unchained/component-library";
import cn from "classnames";
import { UseQueryResult } from "react-query";
import { useSelector } from "react-redux";

import * as UCIcon from "Components/UCIcon";
import { getOwnVaults } from "Redux/selectors";
import { useCurrentOrgDeprecated } from "Redux/selectors/hooks";
import { useFlatNotifications } from "Shared/api/hooks/notifications";
import { usePopover } from "Utils/hooks";

import { getNotificationItem, NotificationItem } from "../../NotificationItems";
import { AvatarRow, AvatarRowLeft } from "./AccountSwitcher/AvatarRows";
import { useCloseOnBreakpoint } from "./hooks";

const NotificationsList = ({
  notifications,
  setClosed,
}: {
  notifications: NotificationItem[];
  setClosed: () => void;
}) => {
  return (
    <>
      {notifications.map((item, index) => {
        const { title, Icon, urgent, subtitle, to, additionalAction } = item;

        const avatarColor = urgent
          ? (tailwindTheme.colors.red["600"] as unknown as AvatarProps["color"])
          : "darkBlue";

        const handleOnClick = () => {
          if (additionalAction) {
            additionalAction();
          }
          setClosed();
        };

        return (
          <AvatarRow
            to={to}
            key={`${index}-${title}`}
            style={{ textDecoration: "none" }}
            onClick={handleOnClick}
            className="hover:bg-gray-100"
          >
            <AvatarRowLeft
              subtitle={subtitle ? subtitle : title}
              title={subtitle ? title : undefined}
              avatar={
                <Avatar
                  color={avatarColor}
                  size="sm"
                  icon={<Icon htmlColor="#fff" />}
                  name={title}
                />
              }
            />
          </AvatarRow>
        );
      })}
    </>
  );
};

const EmptyState = ({ request }: { request: UseQueryResult }) => {
  const status = request.status;
  return (
    <div className="p-4">
      <p>
        {{
          loading: "Fetching the latest",
          success: "No current actions required",
          idle: "Refresh the page to see the latest notifications",
        }[status] || "Something went wrong. Check back again later."}
      </p>
    </div>
  );
};

export const Notifications = ({ mobile = false, disabled = false }) => {
  const notificationRequest = useFlatNotifications();
  const rawNotifications = notificationRequest.data || [];
  const ownedVaults = useSelector(getOwnVaults);

  const org = useCurrentOrgDeprecated();
  const notificationItems = rawNotifications
    .map(notification => getNotificationItem(notification, org, ownedVaults))
    .filter(component => Boolean(component));
  const { triggerRef, setOpen, isOpen, setClosed, popoverRef } = usePopover();
  useCloseOnBreakpoint("md", mobile, isOpen, setClosed);

  const toggleNotifications = isOpen ? setClosed : setOpen;

  const notificationCount = notificationItems.length;

  return (
    <>
      <button
        ref={triggerRef}
        className={cn(
          "relative flex items-center justify-center rounded-full bg-white p-1 text-sm text-primary-600",
          disabled ? "cursor-not-allowed" : "cursor-pointer"
        )}
        onClick={toggleNotifications}
        data-testid="notifications-bell"
        disabled={disabled}
      >
        {notificationCount ? (
          <span className="absolute -right-1 -top-1 h-[18px] min-w-[18px] rounded-full bg-red-600 px-1 text-xs text-white">
            {notificationCount}
          </span>
        ) : null}

        <UCIcon.Notifications className="h-5 w-5" />
      </button>

      <Popover
        open={isOpen}
        anchorEl={popoverRef}
        onClose={setClosed}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "bottom", horizontal: "left" }}
        style={{ left: -10, top: 40 }} // Position the popover near the bottom right
      >
        <div className="max-h-80 max-w-sm overflow-y-auto">
          <h4 className="bg-gray-200 px-4 py-2 text-gray-800">Account notifications</h4>
          {notificationItems.length ? (
            <NotificationsList notifications={notificationItems} setClosed={setClosed} />
          ) : (
            <EmptyState request={notificationRequest} />
          )}
        </div>
      </Popover>
    </>
  );
};
